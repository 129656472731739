import React from 'react';
import { Checkbox } from "semantic-ui-react";

import './CustomCheckbox.css';

const customCheckbox = ({ checked, id = null, withBorder = false, disabled = false, onChange }) => {
  return (
    <Checkbox
      className={withBorder ? 'with-border' : null}
      checked={checked}
      disabled={disabled}
      id={id}
      onChange={onChange}
    />
  );
};

export default customCheckbox;
