import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react'
import { connect } from "react-redux";

import './BaseFilter.css';
import CustomSelect from '../../../form/select/CustomSelect';
import StatisticsInterval from "./StatisticsInterval";
import { getCompaniesForSelectByType } from "../../../../store/actions/statistics/commonActions";
import translation from "../../../translations/translation";
import { PREDEFINED_INTERVALS } from "../../../../config/settings";

class BaseFilter extends Component {
  componentDidMount() {
    const { getCompaniesForSelectByType, type } = this.props;
    getCompaniesForSelectByType(type);
  }

  onCustomIntervalChange = (from, to) => {
    const { onCustomIntervalChange } = this.props;
    this.setState({
      intervalValue: PREDEFINED_INTERVALS.CUSTOM
    }, onCustomIntervalChange(from, to));
  };

  render() {
    const {
      intervalValue, year, selectedCompany, companies, customInterval,
      onCompanyChange, onIntervalChange, onYearChange
    } = this.props;
    const companySelector = <CustomSelect
      options={companies}
      value={selectedCompany}
      placeholder={translation.trans('company')}
      onChange={onCompanyChange}
    />;

    return (
      <Container className='filter-container'>
        <Grid centered verticalAlign='middle'>
          <Grid.Row only='computer'>
            <Grid.Column width={7}>
              {companySelector}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row only='mobile tablet'>
            <Grid.Column stretched>
              {companySelector}
            </Grid.Column>
          </Grid.Row>

          <StatisticsInterval
            year={year}
            startDate={customInterval && customInterval.from}
            endDate={customInterval && customInterval.to}
            intervalValue={intervalValue}
            onIntervalChange={onIntervalChange}
            onCustomIntervalChange={this.onCustomIntervalChange}
            onYearChange={onYearChange}
          />
        </Grid>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    companies: [...state.common.companies]
  };
}

export default connect(mapStateToProps, { getCompaniesForSelectByType })(BaseFilter);
