import axios from 'axios';
import { BASE_URL } from '../../baseUrl';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: { common: { 'Content-Type': 'application/json' } }
});

/**
 * If the token exists (user is authenticated), this method sets the 'Authorization' header in every requests with
 * the that token. If the token doesn't exists (user is not authenticated), it removes the 'Authorization' header from
 * the requests.
 *
 * @param token
 */
function setAuthorizationToken(token) {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
}

export { axiosInstance, setAuthorizationToken };
