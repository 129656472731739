import React, { Component } from 'react';
import { Responsive, Pagination, Container } from 'semantic-ui-react'
import { DEFAULT_QUERY_LIMIT } from "../../config/settings";

import './CustomPagination.css';

class CustomPagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 1
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activePage } = this.props;
    if (activePage && prevProps.activePage !== activePage && activePage !== this.state.activePage) {
      this.setState({ activePage });
    }
  }

  onPageChange = (e, pagination) => {
    this.setState({
      activePage: pagination.activePage
    }, () => {
      this.props.onPageChange(this.state.activePage);
    });
  };

  render() {
    const { isSmall, isDisabled = false, loading } = this.props;
    const { activePage } = this.state;
    const totalPages = Math.ceil(this.props.totalItems / DEFAULT_QUERY_LIMIT);
    const smallPagination = <Pagination
      disabled={isDisabled}
      activePage={activePage}
      totalPages={totalPages}
      onPageChange={this.onPageChange}
      firstItem={null}
      lastItem={null}
      boundaryRange={0}
      siblingRange={0}
      size='massive'
    />;

    return !loading && (
      <Container fluid={true} textAlign='center'>
        <Responsive minWidth={992}>
          {isSmall ? smallPagination :
            <Pagination
              disabled={isDisabled}
              activePage={activePage}
              totalPages={totalPages}
              onPageChange={this.onPageChange}
              size='massive'
            />
          }
        </Responsive>

        <Responsive maxWidth={991}>
          {smallPagination}
        </Responsive>
      </Container>
    );
  }
}

export default CustomPagination;
