import React, { Component } from 'react';
import { Confirm } from 'semantic-ui-react'

import { PREDEFINED_INTERVALS, INTERVALS_WITH_ACTIVE_YEAR_SELECTOR } from '../../../config/settings';
import { getYearOptions } from '../../../utils/common';
import translation from "../../translations/translation";

export default function (ComposedComponent) {
  class Interval extends Component {
    constructor(props) {
      super(props);

      this.state = {
        startDate: null,
        endDate: null,
        incorrectStartOrEndDateModalIsOpen: false
      }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const { intervalValue } = this.props;
      if (prevProps.intervalValue === intervalValue) {
        return;
      }
      if (prevProps.intervalValue === PREDEFINED_INTERVALS.CUSTOM && intervalValue !== PREDEFINED_INTERVALS.CUSTOM) {
        this.setState({
          startDate: null,
          endDate: null
        });
      }
      this._setYearSelectorValue();
    }

    setIncorrectStartOrEndDateModalIsOpen = incorrectStartOrEndDateModalIsOpen => {
      this.setState({ incorrectStartOrEndDateModalIsOpen });
    };

    onIntervalStartDateChange = startDate => {
      const { onCustomIntervalChange } = this.props;
      const { endDate } = this.state;
      if (!!endDate && new Date(startDate) > new Date(endDate)) {
        this.setIncorrectStartOrEndDateModalIsOpen(true);
        return;
      }
      this.setState({ startDate }, onCustomIntervalChange(startDate, endDate));
    };

    onIntervalEndDateChange = endDate => {
      const { onCustomIntervalChange } = this.props;
      const { startDate } = this.state;
      if (!!startDate && new Date(startDate) > new Date(endDate)) {
        this.setIncorrectStartOrEndDateModalIsOpen(true);
        return;
      }
      this.setState({ endDate }, onCustomIntervalChange(startDate, endDate));
    };

    onConfirmIncorrectStartOrEndDate = () => {
      this.setIncorrectStartOrEndDateModalIsOpen(false);
    };

    _setYearSelectorValue = () => {
      const { intervalValue, year, onYearChange } = this.props;
      const yearSelectorIsDisabled = !INTERVALS_WITH_ACTIVE_YEAR_SELECTOR.includes(intervalValue);
      if (!year && !yearSelectorIsDisabled) {
        const yearDefaultValue = !yearSelectorIsDisabled && getYearOptions()[0];
        onYearChange(yearDefaultValue);
      }

      if (year && yearSelectorIsDisabled) {
        onYearChange(null);
      }
    };

    render() {
      const { intervalValue } = this.props;
      const { startDate, endDate, incorrectStartOrEndDateModalIsOpen } = this.state;
      const yearOptions = getYearOptions();
      const yearSelectorIsDisabled = !INTERVALS_WITH_ACTIVE_YEAR_SELECTOR.includes(intervalValue);

      return (
        <React.Fragment>
          <Confirm
            content={translation.trans('start_date_higher_than_end_date')}
            cancelButton={null}
            size='tiny'
            open={incorrectStartOrEndDateModalIsOpen}
            onConfirm={this.onConfirmIncorrectStartOrEndDate}
          />

          <ComposedComponent
            {...this.props}
            startDate={startDate}
            endDate={endDate}
            yearOptions={yearOptions}
            yearSelectorIsDisabled={yearSelectorIsDisabled}
            onIntervalStartDateChange={this.onIntervalStartDateChange}
            onIntervalEndDateChange={this.onIntervalEndDateChange}
          />
        </React.Fragment>
      );
    };
  }

  return Interval;
}