import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import hu from 'date-fns/locale/hu';
import enGB from 'date-fns/locale/en-GB';

import translation from "../../translations/translation";

const customDatePicker = ({ startDate, placeholder, filter, onChange }) => {
  switch (translation.getActiveLocale()) {
    case 'hu':
      registerLocale('hu', hu);
      break;
    case 'en':
    default:
      registerLocale('enGB', enGB);
      break;
  }
  return (
    <React.Fragment>
      <DatePicker
        selected={startDate}
        showWeekNumbers
        filterDate={filter}
        placeholderText={placeholder}
        todayButton={translation.trans('today')}
        maxDate={new Date()}
        locale='hu'
        dateFormat='yyyy/MM/dd'
        className='custom-date-picker'
        onChange={onChange}
      />
    </React.Fragment>
  );
};

export default customDatePicker;
