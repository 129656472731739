import React, { Component } from 'react';
import { Dropdown, Image } from 'semantic-ui-react'

import './LanguageSwitcher.css';
import translation from "../../translations/translation";
import { AVAILABLE_LANGUAGES } from '../../../config/settings';
import { getLanguageConfigFromLocale } from "../../../utils/common";

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props);
  
    const locale = translation.getActiveLocale();
    const activeLanguage = getLanguageConfigFromLocale(locale);
    this.state = {
      activeLanguage
    };
  }

  getFlag = src => {
    return (<Image src={src} className='flag-image' />)
  };

  onLanguageChange = value => {
    const activeLanguage = getLanguageConfigFromLocale(value);
    this.setState({ activeLanguage }, () => {
      translation.setLocale(value);
      window.location.reload();
    });
  };

  render() {
    const { activeLanguage } = this.state;
    const activeFlag = this.getFlag(activeLanguage.image.src);

    return (
      <Dropdown
        item
        icon={null}
        className='language-switcher'
        trigger={activeFlag}
        defaultValue={activeLanguage.value}
        options={Object.values(AVAILABLE_LANGUAGES)}
        onChange={(e, { value }) => this.onLanguageChange(value)}
      />
    );
  }
}

export default LanguageSwitcher;
