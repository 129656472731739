import React from 'react';
import { Container } from "semantic-ui-react";

import './Footer.css';
import CustomButton from '../../../../form/button/CustomButton';
import ButtonWithLoading from "../../../../form/button/ButtonWithLoading";
import translation from "../../../../translations/translation";

const footer = ({ isRunQueryButtonDisabled, isSaveButtonDisabled, onRunQueryClick, onSaveClick }) => {
  return (
    <Container className='footer-container'>
      <ButtonWithLoading
        disabled={isRunQueryButtonDisabled}
        onClickEvent={onRunQueryClick}
        color='blue'>{translation.trans('start_query')}</ButtonWithLoading>
      <CustomButton disabled={isSaveButtonDisabled} onClick={onSaveClick}>
        {translation.trans('save')}</CustomButton>
    </Container>
  );
};

export default footer;