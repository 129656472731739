import React from 'react';
import {Container} from "semantic-ui-react";

import './Spinner.css';

const spinner = () => (
  <Container className='custom-spinner'>
    <i className="fas fa-circle-notch fa-spin" />
  </Container>
);

export default spinner;