import translation from "../components/translations/translation";
import flagGB from '../assets/flags/gb.png';
import flagHU from '../assets/flags/hu.png';

export const DEFAULT_QUERY_LIMIT = 10;

export const SORTING_DIRECTIONS = {
  ACS: { value: 'ASC', iconName: 'fas fa-arrow-up' },
  DESC: { value: 'DESC', iconName: 'fas fa-arrow-down' }
};

export const START_YEAR_IN_DATABASE = 2017;

export const REPORT_NAME_MAX_LENGTH = 40;

export const PREDEFINED_INTERVALS = {
  ACTUAL_YEAR: { label: translation.trans('actual_year'), value: 'actual_year' },
  ACTUAL_MONTH: { label: translation.trans('actual_month'), value: 'actual_month' },
  LAST_WEEK: { label: translation.trans('last_week'), value: 'last_week' },
  LAST_TWO_WEEK: { label: translation.trans('last_two_week'), value: 'last_two_week' },
  LAST_MONTH: { label: translation.trans('last_month'), value: 'last_month' },
  LAST_HALF_YEAR: { label: translation.trans('last_half_year'), value: 'last_half_year' },
  LAST_YEAR: { label: translation.trans('last_year'), value: 'last_year' },
  Q1: { label: 'Q1', value: 'q1' },
  Q2: { label: 'Q2', value: 'q2' },
  Q3: { label: 'Q3', value: 'q3' },
  Q4: { label: 'Q4', value: 'q4' },
  CUSTOM: { label: translation.trans('custom'), value: 'custom' }
};

export const INTERVALS_WITH_ACTIVE_YEAR_SELECTOR = [
  PREDEFINED_INTERVALS.Q1,
  PREDEFINED_INTERVALS.Q2,
  PREDEFINED_INTERVALS.Q3,
  PREDEFINED_INTERVALS.Q4
];

export const CODE_OF_TYPES = {
  KCS: 'KCS',
  KZV: 'KZV',
  ALK: 'ALK',
  HR: 'HR'
};

export const AVAILABLE_LANGUAGES = {
  HU: {
    value: 'hu',
    text: translation.trans('hungarian'),
    image: { src: flagHU }
  },
  EN: {
    value: 'en',
    text: translation.trans('english'),
    image: { src: flagGB }
  }
};