import React, { Component } from 'react';
import DualListBox from 'react-dual-listbox';
import { Container, Divider } from 'semantic-ui-react'
import { connect } from "react-redux";

import './ColumnSelector.css';
import translation from "../../../../translations/translation";
import { getAvailableColumns } from "../../../../../store/actions/reportActions";

class ColumnSelector extends Component {
  componentDidMount() {
    const { getAvailableColumns, reportType } = this.props;
    if (!reportType) {
      return;
    }
    getAvailableColumns(reportType);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { getAvailableColumns, reportType } = this.props;
    if (prevProps.reportType === reportType || !reportType) {
      return;
    }

    getAvailableColumns(reportType);
  }

  render() {
    const { columns, selectedColumns, onColumnsChange } = this.props;

    return (
      !!columns.length ? (
        <React.Fragment>
          <Divider />
          <Container className='column-selector-container'>
            <div className='title'>{translation.trans('selecting_columns')}</div>

            <DualListBox
              options={columns}
              selected={selectedColumns}
              onChange={onColumnsChange}
              preserveSelectOrder
              showOrderButtons
            />
          </Container>
        </React.Fragment>
      ) : null
    );
  }
}

function mapStateToProps(state) {
  return {
    columns: state.report.columns
  };
}

export default connect(mapStateToProps, { getAvailableColumns })(ColumnSelector);