import React from 'react';
import { Grid, Button } from 'semantic-ui-react'

import './ConditionItem.css';
import ReduxCompatibleSelect from "../../../../../form/select/ReduxCompatibleSelect";
import CustomCheckbox from '../../../../../form/switcher/CustomCheckbox';
import CustomButton from '../../../../../form/button/CustomButton';
import TagList from "./tagList/TagList";

export const conditionItem = ({ reportType, title, conditionType, options, values, loadOptions, checked, isValidatingCurrentState, onSelectChange, onCheckboxChange, onConditionTagDelete, onConditionTypeDelete }) => {
  return (
    <React.Fragment>
      <Grid.Row only='computer tablet'>
        <Grid.Column width={1} className='condition-item-checkbox'>
          <CustomCheckbox
            checked={checked}
            onChange={(e, { checked }) => onCheckboxChange(e, { checked }, conditionType)}
          />
        </Grid.Column>
        <Grid.Column width={1}>{title}</Grid.Column>

        <Grid.Column width={10}>
          <ReduxCompatibleSelect
            reportType={reportType}
            options={options}
            placeholder={`${title}...`}
            onChange={(data) => onSelectChange(data, conditionType)}
            loadOptions={loadOptions}
            isValidatingCurrentState={isValidatingCurrentState}
            isMulti={true}
          />
          <TagList items={values} isValidatingCurrentState={isValidatingCurrentState} onConditionTagDelete={onConditionTagDelete} conditionType={conditionType} />
        </Grid.Column>

        <Grid.Column width={1} className='action-button-container' textAlign='right'>
          <Button.Group>
            <CustomButton onClick={() => onConditionTypeDelete(conditionType)} isInGroup={true} popUpTextKey={'delete'}>
              <i className='fas fa-times' /></CustomButton>
          </Button.Group>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row only='mobile'>
        <Grid.Column width={2} className='condition-item-checkbox'>
          <CustomCheckbox
            checked={checked}
            onChange={(e, { checked }) => onCheckboxChange(e, { checked }, conditionType)}
          />
        </Grid.Column>
        <Grid.Column width={10}>{title}</Grid.Column>

        <Grid.Column width={1} className='action-button-container' textAlign='right'>
          <Button.Group>
            <CustomButton onClick={() => onConditionTypeDelete(conditionType)} isInGroup={true} popUpTextKey={'delete'}>
              <i className='fas fa-times' /></CustomButton>
          </Button.Group>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row only='mobile'>
        <Grid.Column stretched>
          <ReduxCompatibleSelect
            reportType={reportType}
            options={options}
            placeholder={`${title}...`}
            onChange={(data) => onSelectChange(data, conditionType)}
            loadOptions={loadOptions}
            isValidatingCurrentState={isValidatingCurrentState}
            isMulti={true}
          />
          <TagList items={values} onConditionTagDelete={onConditionTagDelete} conditionType={conditionType} />
        </Grid.Column>
      </Grid.Row>
    </React.Fragment>
  );
};

export default conditionItem;