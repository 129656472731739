import React from 'react';
import { Container } from 'semantic-ui-react';
import CountUp from 'react-countup';

import './OneNumberChart.css';

export const oneNumberChart = ({ value }) => {
  return (
    <Container className='one-number-chart-container'>
      <CountUp end={Number(value)} duration={2} />
    </Container>
  );
};