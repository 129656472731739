import React, { Component } from 'react';
import { Modal, Button, Input } from 'semantic-ui-react'

import '../Base.css';
import './SaveReport.css';
import translation from "../../translations/translation";
import { REPORT_NAME_MAX_LENGTH } from "../../../config/settings";

class SaveReport extends Component {
  constructor(props) {
    super(props);

    const { reportName } = this.props;
    this.state = {
      reportName: reportName ? reportName : ''
    };
  }

  onReportNameChange = reportName => {
    if (reportName.length > REPORT_NAME_MAX_LENGTH) {
      return;
    }
    this.setState({ reportName });
  };

  onSaveClick = () => {
    const { reportName } = this.state;
    const { onSave } = this.props;
    onSave(reportName);
  };

  render() {
    const { isOpen, onCancel } = this.props;
    const { reportName } = this.state;
    const reportNameLength = reportName ? reportName.length : 0;

    return (
      <Modal open={isOpen} size='tiny' dimmer='blurring' className='save-report-modal'>
        <Modal.Header>{translation.trans('report_settings')}</Modal.Header>
        <Modal.Content>
          <Input size='large' fluid
                 label={translation.trans('name')}
                 value={reportName}
                 onChange={event => this.onReportNameChange(event.target.value)}
          />
          <span className='report-name-length'> {reportNameLength} / {REPORT_NAME_MAX_LENGTH}</span>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCancel}>{translation.trans('cancel')}</Button>
          <Button onClick={this.onSaveClick}>{translation.trans('save')}</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default SaveReport;
