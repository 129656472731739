import {
  STATISTICS_UPDATE_COMPANIES_SELECT_COMPONENT
} from "../../actions/types";

const initialState = {
  companies: []
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case STATISTICS_UPDATE_COMPANIES_SELECT_COMPONENT:
      return {
        ...state,
        companies: action.data
      };
    default:
      return state;
  }
}
