import { FILTER_COMPONENTS } from './common';
import translation from "../../components/translations/translation";

const INBOX = {
  id: 'inbox',
  titleKey: 'inbox',
  type: 'table',
  getDataFunctionName: 'initInboxCount',
  filterComponentName: FILTER_COMPONENTS.ONE_INPUT_FILTER,
  columns: {
    INBOX: { value: 'inbox', label: translation.trans('inbox') },
    VIEWED: { value: 'viewed', label: translation.trans('viewed') },
    UN_VIEWED: { value: 'unviewed', label: translation.trans('un_viewed') }
  }
};
//
// const PROJECTS_COUNT = {
//   id: 'projectsCount',
//   titleKey: 'number_of_projects',
//   type: 'labelingBar',
//   getDataFunctionName: 'initProjectsCount'
// };
//
// const PROJECTS_BY_CLASS = {
//   id: 'projectsByClass',
//   titleKey: 'projects_by_class',
//   type: 'bar',
//   getDataFunctionName: 'initProjectsByClass'
// };
//
// const RECRUITMENT_TYPES = {
//   id: 'recruitmentTypes',
//   titleKey: 'recruitment_types',
//   type: 'bar',
//   getDataFunctionName: 'initRecruitmentTypes'
// };
//
// const PERMANENT_EMPLOYMENT = {
//   id: 'permanentEmployment',
//   titleKey: 'permanent_employment',
//   type: 'stackedBar',
//   getDataFunctionName: 'initPermanentEmployment'
// };
//
// const CASUAL_EMPLOYMENT = {
//   id: 'casualEmployment',
//   titleKey: 'casual_employment',
//   type: 'stackedBar',
//   getDataFunctionName: 'initCasualEmployment'
// };

export const ALL_PROJECTS_CHARTS = {
  INBOX
};