import {
  STATISTICS_PROJECTS_UPDATE_INBOX_COUNT,
  STATISTICS_PROJECTS_UPDATE_PROJECTS_COUNT,
  STATISTICS_PROJECTS_UPDATE_PROJECTS_BY_CLASS,
  STATISTICS_PROJECTS_UPDATE_RECRUITMENT_TYPES,
  STATISTICS_PROJECTS_UPDATE_PERMANENT_EMPLOYMENT,
  STATISTICS_PROJECTS_UPDATE_CASUAL_EMPLOYMENT
} from '../../actions/types';
import { axiosInstance } from "../../../config/axios/axiosInstance";

const initInboxCount = ({ jobNumber }) => dispatch => new Promise((resolve, reject) => {
  const params = {
    jn: jobNumber ? jobNumber : null
  };

  return axiosInstance.get('/ext/v2/report/project/inbox', { params })
    .then(response => {
      const { data, status } = response;
      if (!data || status !== 200) {
        reject();
      }
      dispatch({
        type: STATISTICS_PROJECTS_UPDATE_INBOX_COUNT,
        data: data.data
      });
      resolve();
    })
    .catch(error => {
      console.log('load employment registration count is failed', error);
      reject();
    });
});

const initProjectsCount = () => dispatch => new Promise((resolve, reject) => {
  const data = [
    ['City', '2010 Population', '2000 Population'],
    ['New York City, NY', 8175000, 8008000],
    ['Los Angeles, CA', 3792000, 3694000],
    ['Chicago, IL', 2695000, 2896000],
    ['Houston, TX', 2099000, 1953000],
    ['Philadelphia, PA', 1526000, 1517000],
  ];

  setTimeout(() => {
    dispatch({
      type: STATISTICS_PROJECTS_UPDATE_PROJECTS_COUNT,
      data
    });
    resolve();
  }, 1500);
});

const initProjectsByClass = () => dispatch => new Promise((resolve, reject) => {
  const data = [
    ['City', '2010 Population', '2000 Population'],
    ['New York City, NY', 8175000, 8008000],
    ['Los Angeles, CA', 3792000, 3694000],
    ['Chicago, IL', 2695000, 2896000],
    ['Houston, TX', 2099000, 1953000],
    ['Philadelphia, PA', 1526000, 1517000],
  ];

  setTimeout(() => {
    dispatch({
      type: STATISTICS_PROJECTS_UPDATE_PROJECTS_BY_CLASS,
      data
    });
    resolve();
  }, 1500);
});

const initRecruitmentTypes = () => dispatch => new Promise((resolve, reject) => {
  const data = [
    ['City', '2010 Population', '2000 Population'],
    ['New York City, NY', 8175000, 8008000],
    ['Los Angeles, CA', 3792000, 3694000],
    ['Chicago, IL', 2695000, 2896000],
    ['Houston, TX', 2099000, 1953000],
    ['Philadelphia, PA', 1526000, 1517000],
  ];

  setTimeout(() => {
    dispatch({
      type: STATISTICS_PROJECTS_UPDATE_RECRUITMENT_TYPES,
      data
    });
    resolve();
  }, 1500);
});

const initPermanentEmployment = () => dispatch => new Promise((resolve, reject) => {
  const data = [
    ['City', '2010 Population', '2000 Population'],
    ['New York City, NY', 8175000, 8008000],
    ['Los Angeles, CA', 3792000, 3694000],
    ['Chicago, IL', 2695000, 2896000],
    ['Houston, TX', 2099000, 1953000],
    ['Philadelphia, PA', 1526000, 1517000],
  ];

  setTimeout(() => {
    dispatch({
      type: STATISTICS_PROJECTS_UPDATE_PERMANENT_EMPLOYMENT,
      data
    });
    resolve();
  }, 1500);
});

const initCasualEmployment = () => dispatch => new Promise((resolve, reject) => {
  const data = [
    ['City', '2010 Population', '2000 Population'],
    ['New York City, NY', 8175000, 8008000],
    ['Los Angeles, CA', 3792000, 3694000],
    ['Chicago, IL', 2695000, 2896000],
    ['Houston, TX', 2099000, 1953000],
    ['Philadelphia, PA', 1526000, 1517000],
  ];

  setTimeout(() => {
    dispatch({
      type: STATISTICS_PROJECTS_UPDATE_CASUAL_EMPLOYMENT,
      data
    });
    resolve();
  }, 1500);
});

export {
  initInboxCount,
  initProjectsCount,
  initProjectsByClass,
  initRecruitmentTypes,
  initPermanentEmployment,
  initCasualEmployment
};

