import React from 'react';
import { Input } from "semantic-ui-react";

import './Input.css';

const customInput = ({ value, placeholder, onChange }) => {
  return (
    <Input
      placeholder={placeholder}
      defaultValue={value}
      onChange={onChange}
    />
  );
};

export default customInput;