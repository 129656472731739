import React, { Component } from 'react';
import { connect } from 'react-redux';
import translation from "../translations/translation";
import { login } from '../../store/actions/authActions';
import probond_logo from './probond-logo.png';
import './LoginPage.css';

class LoginPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      username: '',
      password: '',
      errors: [],
      isLoading: false
    };
  }
  
  onSubmit(e) {
    e.preventDefault();
    
    this.setState({ errors: [], isLoading: true }, () => {
      const { username, password } = this.state;
      const loginData = {
        username,
        password
      };
      this.props.login(loginData)
        .then(() => this.props.history.push('/'))
        .catch(err => {
          if (!err || !err.response) {
            this.setState({
              username: '',
              password: '',
              errorMessage: null,
              isLoading: false
            });
            return;
          }
      
          const statusCode = err.response.status;
          let errorMessage;
          switch (statusCode) {
            case 400:
            case 401:
              errorMessage = 'authentication_failed';
              break;
            case 500:
              errorMessage = 'fatal_error_during_login';
              break;
            default:
              errorMessage = null;
              break;
          }
          
          const errors = [ ...this.state.errors ];
          errors.push(errorMessage);
      
          this.setState({
            username: '',
            password: '',
            errors,
            isLoading: false
          });
        })
    });
  }
  
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  
  render() {
    const { errors, username, password, isLoading } = this.state;
    
    return (
      <div id='login-background'>
        <div id="login-container">
          <div id="login-form-wrapper">
            <div id="login-header">
              <img src={probond_logo} alt='Probond Logo' /> Probond {translation.trans('statistics')}
            </div>
            <div id="error-messages">
              {errors.map((e, i) => (<div key={i} className="error-msg">{e}</div>))}
            </div>
            <form onSubmit={this.onSubmit.bind(this)}>
              <div className="form-content">
                <input type="text" name="username" placeholder={translation.trans('username')} value={username} required="required" onChange={this.onChange.bind(this)}/>
                <input type="password" name="password" placeholder={translation.trans('password')} value={password} required="required" onChange={this.onChange.bind(this)}/>
                <input type="submit" className="button" value={translation.trans('login')} disabled={isLoading} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { login })(LoginPage);
