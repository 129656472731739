import {
  REPORT_TYPES,
  REPORT_CONDITIONS,
  REPORT_STATUSES
} from "../config/reports";
import { filterIdsFromObjectsOfArray } from '../utils/common';

const getReportTypeConfigByCode = code =>
  REPORT_TYPES.find(item => item.code === code);

const getReportTitle = status => {
  switch (status) {
    case REPORT_STATUSES.SUCCESS.value:
      return REPORT_STATUSES.SUCCESS.label;
    case REPORT_STATUSES.PENDING.value:
      return REPORT_STATUSES.PENDING.label;
    case REPORT_STATUSES.FAILED.value:
      return REPORT_STATUSES.FAILED.label;
    default:
      return 'N/A';
  }
};

const getFormattedConditionsToSend = conditions => {
  const formattedConditions = {};
  Object.keys(conditions).forEach(key =>
    formattedConditions[conditions[key].optionsName] = filterIdsFromObjectsOfArray(conditions[key].selectedValues));

  return formattedConditions;
};

const getCheckedConditions = conditions => {
  return Object.keys(conditions).reduce((checkedConditions, key) => {
    if (conditions[key].checked) {
      checkedConditions[key] = conditions[key];
    }
    return checkedConditions;
  }, {});
};

const hasSelectedConditionTags = conditions => {
  if (!conditions) {
    return false;
  }
  const checkedConditions = getCheckedConditions(conditions);

  return !!Object.values(checkedConditions).find(condition =>
    !!condition.selectedValues.length);
};

const getConditionsWithConfig = conditions => {
  const filledConditions = {};
  // get those condition configs which is in conditions
  const usedConditionConfigs = Object.values(REPORT_CONDITIONS).filter(config =>
    Object.keys(conditions).includes(config.optionsName));
  // collect the filtered configs with selected values
  usedConditionConfigs.forEach(config => {
    const conditionByConfig = conditions[config.optionsName];
    if (conditionByConfig) {
      const selectedValues = Object.keys(conditionByConfig).map((key, index) =>
        ({
          label: Object.values(conditionByConfig)[index],
          value: key,
          id: key,
        })
      );
      filledConditions[config.value] = {
        ...config,
        selectedValues,
        checked: true
      };
    }
  });

  return filledConditions;
};

const hasAnyDataInTheArraysOfObject = object => {
  const result = Object.values(object).find(array => !!array.length);
  return result && !!result.length;
};

export {
  getReportTypeConfigByCode,
  getReportTitle,
  getCheckedConditions,
  getFormattedConditionsToSend,
  getConditionsWithConfig,
  hasSelectedConditionTags,
  hasAnyDataInTheArraysOfObject
};