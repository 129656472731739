import React from 'react';
import { Header } from 'semantic-ui-react'

import './Title.css';

const title = ({ title }) => {
  return (
    <div className='page-title'>
      <Header as='h1' textAlign='center'>
        {title}
      </Header>
    </div>
  );
};

export default title;
