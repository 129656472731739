import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollTo extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location === prevProps.location) {
      return;
    }

    if (!this.props.location.hash) {
      window.scrollTo(0, 0);
      return;
    }

    const id = this.props.location.hash.replace('#', '');
    const element = document.getElementById(id);
    if (!element) {
      return;
    }

    window.scrollTo(0, element.offsetTop);
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollTo);
