import React, { Component } from 'react';
import { Container } from 'semantic-ui-react'
import { connect } from "react-redux";

import './SavedConditionList.css';
import translation from "../../../translations/translation";
import {
  getProfileSavedReportsList,
  getUserList,
  deleteReport,
  loadReport,
  shareSavedConditions
} from "../../../../store/actions/reportActions";
import Spinner from "../../../spinner/Spinner";
import SavedConditionItem from "./savedConditionItem/SavedConditionItem";
import UserListToShareModal from "../../../modals/userListToShare/UserListToShare";
import { getReportTypeConfigByCode } from "../../../../utils/report";
import CustomPagination from "../../../pagination/CustomPagination";

class SavedConditionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      userListToShareModalIsOpen: false,
      selectedConditionToShare: null,
      activePage: 1,
      hasToResetUserListModal: false
    };
  }

  async componentDidMount() {
    await this.loadSavedReportList();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { hasToRefresh } = this.props;
    if (prevProps.hasToRefresh !== hasToRefresh && hasToRefresh) {
      await this.loadSavedReportList();
    }
  }

  loadSavedReportList = async () => {
    const { getProfileSavedReportsList, getUserList, setHasToRefreshSavedConditionList } = this.props;
    const { activePage } = this.state;
    await this.setLoading(true);
    getUserList();
    getProfileSavedReportsList(activePage).finally(() => {
      setHasToRefreshSavedConditionList(false);
      this.setLoading(false);
    });
  };

  setLoading = async loading =>
    this.setState({ loading });

  getSavedReportList = () => {
    const { savedReports, userListToShare, isValidatingCurrentState } = this.props;
    if (!savedReports || !savedReports.totalItems) {
      return translation.trans('no_item_to_show');
    }

    return savedReports.items.map((item, index) =>
      <SavedConditionItem
        key={index}
        title={item.title}
        isDisabled={isValidatingCurrentState}
        onClick={() => this.onListItemViewClick(item.id, item.code)}
        onShareClick={userListToShare.length ? () => this.onListItemShareClick(item) : null}
        onDeleteClick={() => this.onListItemDeleteClick(item.id)}
      />
    );
  };

  onListItemViewClick = (id, code) => {
    const { loadReport, loadSavedReport } = this.props;
    loadReport(id).then(() => {
      const { selectedReport } = this.props;
      if (!selectedReport.query || !selectedReport.title || !getReportTypeConfigByCode(code)) {
        alert(translation.trans('missing_parameter_to_load_saved_condition'));
        return;
      }
      loadSavedReport(selectedReport.query, selectedReport.title, code, selectedReport.columns, selectedReport.projectStatuses);
    });
  };

  onListItemShareClick = selectedConditionToShare => {
    this.setState({ selectedConditionToShare, userListToShareModalIsOpen: true, hasToResetUserListModal: false });
  };

  onListItemDeleteClick = async id => {
    deleteReport(id).then(() => {
      const { savedReports } = this.props;
      const { activePage } = this.state;
      if (savedReports.items.length === 1 && activePage !== 1) {
        this.setState({ activePage: activePage - 1 }, this.loadSavedReportList);
        return;
      }
      this.loadSavedReportList();
    });
  };

  onPaginationChange = activePage => {
    const { getProfileSavedReportsList } = this.props;
    this.setState({ activePage }, () => getProfileSavedReportsList(activePage));
  };

  onUserListToShareModalSaveClick = (id, selectedUsers, isSharable) => {
    shareSavedConditions(id, selectedUsers, isSharable)
      .then(() => {
        alert(translation.trans('sharing_success'));
      })
      .catch(() => {
        alert(translation.trans('sharing_failed'));
      })
      .finally(() => {
        this.setState({
          userListToShareModalIsOpen: false,
          selectedConditionToShare: null,
          hasToResetUserListModal: true
        });
      });
  };

  onUserListToShareModalCloseClick = () => {
    this.setState({ userListToShareModalIsOpen: false, selectedConditionToShare: null, hasToResetUserListModal: true });
  };

  render() {
    const { userListToShare, savedReports, isValidatingCurrentState } = this.props;
    const {
      loading,
      userListToShareModalIsOpen,
      selectedConditionToShare,
      activePage,
      hasToResetUserListModal
    } = this.state;
    const list = this.getSavedReportList();

    return (
      <Container className='saved-condition-list-container'>
        <UserListToShareModal
          isOpen={userListToShareModalIsOpen}
          userList={userListToShare}
          selectedUsers={selectedConditionToShare && selectedConditionToShare.usersSharedWith}
          isPublic={selectedConditionToShare && selectedConditionToShare.sharable}
          queryId={selectedConditionToShare && selectedConditionToShare.id}
          hasToReset={hasToResetUserListModal}
          onSave={this.onUserListToShareModalSaveClick}
          onClose={this.onUserListToShareModalCloseClick}
        />

        <div className='title'>{translation.trans('saved_conditions')}</div>
        {loading ? <Spinner /> : list}
        {
          !!list.length && <CustomPagination
            isSmall={true}
            loading={loading}
            activePage={activePage}
            isDisabled={isValidatingCurrentState}
            totalItems={savedReports.totalItems}
            onPageChange={this.onPaginationChange}
          />
        }
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    savedReports: { ...state.report.savedReports },
    userListToShare: [...state.report.userListToShare],
    selectedReport: state.report.selectedReport
  };
}

export default connect(mapStateToProps, {
  getProfileSavedReportsList,
  getUserList,
  loadReport
})(SavedConditionList);
