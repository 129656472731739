import React from 'react';
import { Button, Popup } from "semantic-ui-react";

import './Button.css';
import translation from "../../translations/translation";

const customButton = ({ children, disabled = false, size = null, popUpTextKey, isInGroup = false, onClick }) => {
  const dynamicClass = isInGroup ? 'in-group' : '';

  return (
    <Popup content={!!popUpTextKey ? translation.trans(popUpTextKey) : null} trigger={
      <Button
        onClick={onClick}
        className={dynamicClass}
        disabled={disabled}
        size={size}
      >
        {children}
      </Button>} disabled={!popUpTextKey} />
  );
};

export default customButton;
