import React from 'react';
import { Container, Grid, Header } from 'semantic-ui-react'

import './ChartWrapper.css';
import translation from "../../translations/translation";
import { FILTER_COMPONENTS } from '../../../config/statistics/common';
import BaseFilter from '../filter/baseFilter/BaseFilter';
import OneInputFilter from '../filter/oneInputFilter/OneInputFilter';
import Spinner from "../../spinner/Spinner";

const chartWrapper = ({
                        type, chart, titleKey, selectedCompany, intervalValue, year, loading, customInterval,
                        filterType, jobNumber, canNextClick, canPreviousClick, onNextClick, onPreviousClick,
                        onCompanyChange, onCustomIntervalChange, onIntervalChange, onYearChange, onJobNumberChange,
                        onStartButtonClick
                      }) => {
  const dynamicClassNext = !canNextClick ? 'inactive' : null;
  const dynamicClassPrevious = !canPreviousClick ? 'inactive' : null;
  const filterComponent = filterType === FILTER_COMPONENTS.ONE_INPUT_FILTER ?
    <OneInputFilter
      value={jobNumber}
      placeholder={`${translation.trans('job_number')}...`}
      onValueChange={onJobNumberChange}
      onStartButtonClick={onStartButtonClick}
    /> :
    <BaseFilter
      selectedCompany={selectedCompany}
      type={type}
      year={year}
      customInterval={customInterval}
      intervalValue={intervalValue}
      onCompanyChange={onCompanyChange}
      onCustomIntervalChange={onCustomIntervalChange}
      onIntervalChange={onIntervalChange}
      onYearChange={onYearChange}
    />;

  return (
    <Container className='chart-wrapper'>
      <Header as='h1'>{translation.trans(titleKey)}</Header>

      {filterComponent}

      <Grid stackable centered>
        <Grid.Column
          width={1}
          textAlign='center'
          verticalAlign='middle'
          className={['chart-navigation-arrow', dynamicClassPrevious].join(' ')}
          onClick={canPreviousClick ? onPreviousClick : null}
        >
          <i className='fas fa-caret-left' />
        </Grid.Column>
        <Grid.Column className='chart' width={12} textAlign='center' verticalAlign='middle'>
          {loading ? <Spinner /> : chart}
        </Grid.Column>
        <Grid.Column
          width={1}
          textAlign='center'
          verticalAlign='middle'
          className={['chart-navigation-arrow', dynamicClassNext].join(' ')}
          onClick={canNextClick ? onNextClick : null}
        >
          <i className='fas fa-caret-right' />
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default chartWrapper;
