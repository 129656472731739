import React from 'react';
import { Container } from 'semantic-ui-react'

import './TagList.css';
import TagItem from "./tagItem/TagItem";

const tagList = ({ items, isValidatingCurrentState, conditionType, onConditionTagDelete }) => {
  return (
    <Container className='tag-list-container'>
      {
        items ? items.map((tag, index) =>
            <TagItem
              key={index}
              label={tag.label}
              isDisabled={isValidatingCurrentState}
              onDeleteClick={() => !isValidatingCurrentState && onConditionTagDelete(tag.id, conditionType)}
            />)
          : null
      }
    </Container>
  );
};

export default tagList;