import React from 'react';
import { Container } from 'semantic-ui-react'

import './NotFoundPage.css';
import translation from "../translations/translation";
import CustomButton from '../form/button/CustomButton';

const notFoundPage = ({ history }) => {
  return (
    <Container className='not-found-page-container'>
      <Container className='content'>
        <div className='code'>404</div>
        <div>
          {translation.trans('page_not_found')}...
        </div>
        <div className='go-to-dashboard'>
          <CustomButton size='big' onClick={() => {
            history.push('/')
          }}>{translation.trans('dashboard')}</CustomButton>
        </div>
      </Container>
    </Container>
  );
};

export default notFoundPage;
