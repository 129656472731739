import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Sticky, Segment, Menu, Dropdown, Container, Grid } from 'semantic-ui-react'

import { ROUTES } from '../../config/routes';
import { logout } from "../../store/actions/authActions";
import translation from "../translations/translation";
import Breadcrumb from "./breadcrumb/Breadcrumb";
import BackButton from './backButton/BackButton';
import LanguageSwitcher from "./languageSwitcher/LanguageSwitcher";
import './LoggedInWrapper.css';

export default function (WrappedComponent) {
  class LoggedInWrapper extends Component {
    onLogoutClick = () => {
      this.props.logout();
      this.props.history.push('login');
    };

    onMenuItemClick = (url) => {
      this.props.history.push(url);
    };

    render() {
      const { username } = this.props;

      return (
        <React.Fragment>
          <Sticky>
            <Segment className='menu-container'>
              <Menu secondary stackable size='massive'>
                <Menu.Item onClick={() => this.onMenuItemClick(ROUTES.DASHBOARD.url)}>
                  {translation.trans(ROUTES.DASHBOARD.labelKey)}
                </Menu.Item>

                <Menu.Menu position='right'>
                  <LanguageSwitcher />

                  <Dropdown item text={(username) ? username : ''}>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => this.onMenuItemClick(`/profile`)}
                        text={translation.trans(ROUTES.PROFILE.labelKey)}
                      />
                      <Dropdown.Item onClick={this.onLogoutClick} text={translation.trans('logout')} />
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Menu>
              </Menu>
            </Segment>
          </Sticky>

          <Container className='content-container'>
            <Grid stackable className='content-grid'>
              <Grid.Row columns={2}>
                <Grid.Column textAlign='center' verticalAlign='middle' width={9} className='toolbar'>
                  <BackButton {...this.props} />
                  <Breadcrumb {...this.props} />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column textAlign='left'>
                  <WrappedComponent {...this.props} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </React.Fragment>
      );
    }
  }

  function mapStateToProps(state) {
    return {
      username: state.auth.user.username
    };
  }

  return connect(mapStateToProps, { logout })(LoggedInWrapper);
}
