export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const STATISTICS_SET_ACTIVE_CHART = 'STATISTICS_SET_ACTIVE_CHART';
export const STATISTICS_UPDATE_COMPANIES_SELECT_COMPONENT = 'STATISTICS_UPDATE_COMPANIES_SELECT_COMPONENT';

export const STATISTICS_PROJECTS_UPDATE_INBOX_COUNT = 'STATISTICS_PROJECTS_UPDATE_INBOX_COUNT';
export const STATISTICS_PROJECTS_UPDATE_PROJECTS_COUNT = 'STATISTICS_PROJECTS_UPDATE_PROJECTS_COUNT';
export const STATISTICS_PROJECTS_UPDATE_PROJECTS_BY_CLASS = 'STATISTICS_PROJECTS_UPDATE_PROJECTS_BY_CLASS';
export const STATISTICS_PROJECTS_UPDATE_RECRUITMENT_TYPES = 'STATISTICS_PROJECTS_UPDATE_RECRUITMENT_TYPES';
export const STATISTICS_PROJECTS_UPDATE_PERMANENT_EMPLOYMENT = 'STATISTICS_PROJECTS_UPDATE_PERMANENT_EMPLOYMENT';
export const STATISTICS_PROJECTS_UPDATE_CASUAL_EMPLOYMENT = 'STATISTICS_PROJECTS_UPDATE_CASUAL_EMPLOYMENT';

export const STATISTICS_CANDIDATES_UPDATE_CANDIDATES_COUNT = 'STATISTICS_CANDIDATES_UPDATE_CANDIDATES_COUNT';
export const STATISTICS_CANDIDATES_UPDATE_REGISTERED_CANDIDATES_COUNT = 'STATISTICS_CANDIDATES_UPDATE_REGISTERED_CANDIDATES_COUNT';
export const STATISTICS_CANDIDATES_UPDATE_DEMOGRAPHY_STATISTICS = 'STATISTICS_CANDIDATES_UPDATE_DEMOGRAPHY_STATISTICS';

export const STATISTICS_ADVERTS_UPDATE_ADVERTS_COUNT = 'STATISTICS_ADVERTS_UPDATE_ADVERTS_COUNT';
export const STATISTICS_ADVERTS_UPDATE_PLACE_OF_APPEARANCE = 'STATISTICS_ADVERTS_UPDATE_PLACE_OF_APPEARANCE';
export const STATISTICS_ADVERTS_UPDATE_APPLICATIONS_COUNT = 'STATISTICS_ADVERTS_UPDATE_APPLICATIONS_COUNT';
export const STATISTICS_ADVERTS_UPDATE_APPLICATIONS_BY_PORTAL = 'STATISTICS_ADVERTS_UPDATE_APPLICATIONS_BY_PORTAL';

export const STATISTICS_USERS_UPDATE_USER_GROUPS = 'STATISTICS_USERS_UPDATE_USER_GROUPS';
export const STATISTICS_USERS_UPDATE_TOP_5_MOST_ACTIVE_USER = 'STATISTICS_USERS_UPDATE_TOP_5_MOST_ACTIVE_USER';
export const STATISTICS_USERS_UPDATE_TOP_RECRUITERS = 'STATISTICS_USERS_UPDATE_TOP_RECRUITERS';
export const STATISTICS_USERS_UPDATE_TOP_PROJECT_MANAGERS = 'STATISTICS_USERS_UPDATE_TOP_PROJECT_MANAGERS';

export const STATISTICS_CLIENTS_UPDATE_CLIENTS_COUNT = 'STATISTICS_CLIENTS_UPDATE_CLIENTS_COUNT';
export const STATISTICS_CLIENTS_UPDATE_TOP_CLIENTS = 'STATISTICS_CLIENTS_UPDATE_TOP_CLIENTS';
export const STATISTICS_CLIENTS_UPDATE_CLIENT_REQUESTS = 'STATISTICS_CLIENTS_UPDATE_CLIENT_REQUESTS';

export const STATISTICS_CASUAL_UPDATE_EMPLOYMENT_REGISTRATION_COUNT = 'STATISTICS_CASUAL_UPDATE_EMPLOYMENT_REGISTRATION_COUNT';

export const REPORT_UPDATE_REPORT_TYPES = 'REPORT_UPDATE_REPORT_TYPES';
export const REPORT_UPDATE_COMPANIES_SELECT_COMPONENT = 'REPORT_UPDATE_COMPANIES_SELECT_COMPONENT';
export const REPORT_UPDATE_OFFICES_SELECT_COMPONENT = 'REPORT_UPDATE_OFFICES_SELECT_COMPONENT';
export const REPORT_UPDATE_CLIENTS_SELECT_COMPONENT = 'REPORT_UPDATE_CLIENTS_SELECT';
export const REPORT_UPDATE_PROJECTS_SELECT_COMPONENT = 'REPORT_UPDATE_PROJECTS_SELECT';
export const REPORT_UPDATE_USERS_SELECT_COMPONENT = 'REPORT_UPDATE_USERS';
export const REPORT_GET_AVAILABLE_COLUMNS = 'REPORT_GET_AVAILABLE_COLUMNS';
export const REPORT_RESET_AVAILABLE_COLUMNS = 'REPORT_RESET_AVAILABLE_COLUMNS';
export const REPORT_START_QUERY = 'REPORT_START_QUERY';
export const REPORT_VALIDATE_CURRENT_REPORT_STATE = 'REPORT_VALIDATE_CURRENT_REPORT_STATE';
export const REPORT_RESET_SELECT_COMPONENT_OPTIONS = 'REPORT_RESET_SELECT_COMPONENT_OPTIONS';
export const REPORT_GET_SAVED_REPORTS_LIST = 'REPORT_GET_SAVED_REPORTS_LIST';
export const REPORT_UPDATE_USER_LIST = 'REPORT_UPDATE_USER_LIST';
export const REPORT_LOAD_REPORT = 'REPORT_LOAD_REPORT';

export const PROFILE_GET_REPORT_LIST = 'PROFILE_GET_REPORT_LIST';