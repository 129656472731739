import React, { Component } from 'react';
import { Button, Popup } from "semantic-ui-react";

import './Button.css';
import translation from "../../translations/translation";

class ButtonWithLoading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  onClick = () => {
    this.setState({ isLoading: true }, async () => {
      const { onClickEvent } = this.props;
      await onClickEvent();
      this.setState({ isLoading: false });
    });
  };

  render() {
    const { children, popUpTextKey, isInGroup = false, disabled = false } = this.props;
    const { isLoading } = this.state;
    const dynamicClass = isInGroup ? 'in-group' : '';

    return (
      <Popup content={!!popUpTextKey ? translation.trans(popUpTextKey) : null} trigger={
        <Button disabled={disabled} onClick={this.onClick} loading={isLoading} className={dynamicClass}>
          {children}
        </Button>}
             disabled={!popUpTextKey}
      />
    );
  }
}

export default ButtonWithLoading;
