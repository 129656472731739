import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react'
import { connect } from "react-redux";

import './RequiredConditionsBox.css';
import translation from "../../../../translations/translation";
import CustomSelect from "../../../../form/select/CustomSelect";
import CustomCheckbox from '../../../../form/switcher/CustomCheckbox';
import ReportInterval from "./ReportInterval";
import ProjectStatusSelector from './ProjectStatusSelector';
import { getReportTypes } from "../../../../../store/actions/reportActions";

class RequiredConditionsBox extends Component {
  componentDidMount() {
    const { getReportTypes } = this.props;
    getReportTypes();
  }

  render() {
    const {
      reportTypes, year, projectStatuses,
      intervalValue, reportTypeValue,
      onReportTypeChange, onIntervalChange, onCustomIntervalChange, onYearChange,
      onProjectStatusChange
    } = this.props;

    return (
      <Grid verticalAlign='middle' className='required-conditions-container'>
        <ReportInterval
          year={year}
          intervalValue={intervalValue}
          onIntervalChange={onIntervalChange}
          onCustomIntervalChange={onCustomIntervalChange}
          onYearChange={onYearChange}
        />

        <Grid.Row only='computer tablet'>
          <Grid.Column width={1}><CustomCheckbox checked={true} /></Grid.Column>
          <Grid.Column width={2}>{translation.trans('report_type')}</Grid.Column>
          <Grid.Column width={7}>
            <CustomSelect
              value={reportTypeValue}
              options={reportTypes}
              placeholder={translation.trans('report_type')}
              onChange={onReportTypeChange}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row only='mobile'>
          <Grid.Column width={2}><CustomCheckbox checked={true} /></Grid.Column>
          <Grid.Column width={13}>{translation.trans('report_type')}</Grid.Column>
        </Grid.Row>

        <Grid.Row only='mobile'>
          <Grid.Column stretched>
            <CustomSelect
              value={reportTypeValue}
              options={reportTypes}
              placeholder={translation.trans('report_type')}
              onChange={onReportTypeChange}
            />
          </Grid.Column>
        </Grid.Row>

        <ProjectStatusSelector projectStatuses={projectStatuses} onChange={onProjectStatusChange} />
      </Grid>
    );
  };
}

function mapStateToProps(state) {
  return {
    reportTypes: state.report.reportTypes
  };
}

export default connect(mapStateToProps, { getReportTypes })(RequiredConditionsBox);
