export const ROUTES = {
  DASHBOARD: { url: '/', labelKey: 'dashboard' },
  LOGIN: { url: '/login', labelKey: 'login' },
  PROFILE: { url: '/profile', labelKey: 'profile' },
  REPORT: { url: '/report', labelKey: 'report', parent: 'dashboard' },
  CANDIDATES: { url: '/candidates', labelKey: 'candidates', parent: 'dashboard' },
  CANDIDATES_GROUP: { url: '/candidates/:group', parent: 'candidates' },
  PROJECTS: { url: '/projects', labelKey: 'projects', parent: 'dashboard' },
  ADVERTS: { url: '/adverts', labelKey: 'adverts', parent: 'dashboard' },
  USERS: { url: '/users', labelKey: 'users', parent: 'dashboard' },
  CLIENTS: { url: '/clients', labelKey: 'clients', parent: 'dashboard' },
  CASUAL: { url: '/casual', labelKey: 'casual', parent: 'dashboard' }
};
