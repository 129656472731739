import React from 'react';
import { Container } from 'semantic-ui-react'

import './DatePicker.css';
import translation from "../../translations/translation";
import CustomDatePicker from "./CustomDatePicker";

const customDateInterval = ({ startDate, endDate, onStartDateChange, onEndDateChange }) => {
  return (
    <Container className='custom-date-interval-container'>
      <CustomDatePicker
        placeholder={translation.trans('from')}
        startDate={startDate}
        onChange={onStartDateChange}
      />
      <span />
      <CustomDatePicker
        placeholder={translation.trans('to')}
        startDate={endDate}
        onChange={onEndDateChange}
      />
    </Container>
  );
};

export default customDateInterval;
