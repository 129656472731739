import React from 'react';
import { Radio } from "semantic-ui-react";

import './CustomSwitcher.css';

const customSwitcher = ({ text, checked, disabled = false, onChange }) => {
  return (
    <div className='custom-switcher'>
      <span>{text}</span>
      <Radio
        toggle
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
};

export default customSwitcher;
