import React from 'react';
import { Table, Button, Responsive } from "semantic-ui-react";

import ButtonWithLoading from '../../form/button/ButtonWithLoading';

const reportListFileItem = ({ title, type, status, createDate, onDownloadClick = null }) => {
  const downloadButton = onDownloadClick ? (
    <ButtonWithLoading onClickEvent={onDownloadClick} popUpTextKey='download' isInGroup={true}><i
      className='fas fa-download' /></ButtonWithLoading>
  ) : null;

  return (
    <Table.Row>
      <Table.Cell className='title'>{title}</Table.Cell>
      <Table.Cell className='type'>{type}</Table.Cell>
      <Table.Cell className='status'>{status}</Table.Cell>
      <Table.Cell className='date' width={4}>{createDate}</Table.Cell>
      <Table.Cell textAlign='right'>
        <Responsive minWidth={992}>
          <Button.Group>
            {downloadButton}
          </Button.Group>
        </Responsive>

        <Responsive maxWidth={991}>
          <Button.Group fluid>
            {downloadButton}
          </Button.Group>
        </Responsive>
      </Table.Cell>
    </Table.Row>
  );
};

export default reportListFileItem;
