import React, { Component } from 'react';
import { Container } from 'semantic-ui-react'

import Title from '../../title/Title';
import ChartSelector from '../chartSelector/ChartSelector';
import ChartWrapper from '../chartWrapper/ChartWrapper';
import { BarChart, PieChart, LineChart, OneNumberChart, TableChart } from "../charts";
import translation from "../../translations/translation";
import { PREDEFINED_INTERVALS } from "../../../config/settings";

export default function (ComposedComponent) {
  class BaseModule extends Component {
    constructor(props) {
      super(props);

      this.state = {
        title: null,
        type: null,
        charts: [],
        selectedCompany: null,
        year: null,
        interval: null,
        customInterval: null,
        activeChartIndex: 0,
        activeGroupIndex: null,
        currentLocation: null,
        jobNumber: null,
        loading: false,
        hasToStartQuery: true
      };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const { charts, activeGroupIndex } = this.state;
      const { match } = this.props;

      if (!!match.params.group && activeGroupIndex === null) {
        const newActiveGroupIndex = charts.findIndex(chart => chart.url === match.url);
        this.setState({ activeGroupIndex: newActiveGroupIndex });
      }
    }

    sortChartsAndChartGroups = charts => {
      return {
        charts: charts.filter(c => !c.groupId),
        categories: charts.filter(c => c.groupId)
      };
    };

    getCurrentConfigBasedOnUrl = charts => {
      const additionalUrlParams = this.getAdditionalParamsInUrl();
      if (additionalUrlParams.length === 0) {
        return this.sortChartsAndChartGroups(charts);
      }

      return additionalUrlParams.reduce((res, curr, i) => {
        const currentUrlParam = additionalUrlParams[i];
        return res.find(i => i.groupId === currentUrlParam);
      }, charts);
    };

    getAdditionalParamsInUrl = () => {
      const { match } = this.props;

      return Object.values(match.params);
    };

    getChartComponent = (type, data, columns = null) => {
      switch (type) {
        case 'bar':
          return this._getComponentOrNoData(<BarChart data={data} />, data);
        case 'labelingBar':
          return this._getComponentOrNoData(<BarChart data={data} isLabeling={true} />, data);
        case 'stackedBar':
          return this._getComponentOrNoData(<BarChart data={data} isStacked={true} />, data);
        case 'pie':
          return this._getComponentOrNoData(<PieChart data={data} />, data);
        case 'donut':
          return this._getComponentOrNoData(<PieChart data={data} isDonut={true} />, data);
        case 'line':
          return this._getComponentOrNoData(<LineChart data={data} />, data);
        case 'oneNumber':
          return this._getComponentOrNoData(<OneNumberChart value={data} />, data);
        case 'table':
          return this._getComponentOrNoData(<TableChart data={data} columns={columns} />, data);
        default:
          return <Container>{translation.trans('coming_soon')}...</Container>;
      }
    };

    _getComponentOrNoData = (component, data) => {
      const noData = <Container>{translation.trans('no_data_to_show')}</Container>;
      return data ? component : noData;
    };

    setTypeByCode = type => {
      this.setState({ type });
    };

    setHasToStartQuery = hasToStartQuery => {
      this.setState({ hasToStartQuery });
    };

    setLoading = async loading => {
      await this.setState({ loading });
    };

    onGroupClick = chartIndex => {
      const { charts } = this.state;
      const group = charts[chartIndex];

      this.setState({ activeChartIndex: 0 }, () => {
        this.props.history.push({
          pathname: group.url,
          state: { labelKey: group.titleKey }
        });
      });
    };

    onChartClick = activeChartIndex => {
      this.setState({ activeChartIndex });
    };

    onTitleChange = title => {
      this.setState({ title });
    };

    onLoadAllCharts = charts => {
      const currentLocation = this.props.location.pathname;
      this.setState({ charts, currentLocation, activeChartIndex: 0 });
    };

    onCompanyChange = selectedCompany => {
      this.setState({ selectedCompany });
    };

    onIntervalChange = interval => {
      this.setState({ interval, customInterval: null });
    };

    onCustomIntervalChange = (from, to) => {
      this.setState({
        customInterval: { from, to },
        interval: PREDEFINED_INTERVALS.CUSTOM
      });
    };

    onYearChange = year => {
      this.setState({ year });
    };

    onJobNumberChange = (e, { value }) => {
      this.setState({ jobNumber: value.trim() });
    };

    onLoadChart = chart => {
      const { activeChartIndex, charts } = this.state;
      charts[activeChartIndex] = chart;

      this.setState({ charts });
    };

    onLoadGroup = (group, groupId) => {
      const { charts } = this.state;
      const index = charts.findIndex(item => item.groupId === groupId);

      group.charts.map(chart => {
        chart.chart = this.getChartComponent(chart.type, chart.data);
        return chart;
      });

      charts[index] = group;
      this.setState({ charts });
    };

    onPreviousChartClick = () => {
      const canSelectPrevious = this._canSelectPreviousChart();
      if (!canSelectPrevious) {
        return;
      }

      this.setState(state => ({
        activeChartIndex: state.activeChartIndex - 1
      }));
    };

    onNextChartClick = () => {
      const canSelectNext = this._canSelectNextChart();
      if (!canSelectNext) {
        return;
      }

      this.setState(state => ({
        activeChartIndex: state.activeChartIndex + 1
      }));
    };

    _canSelectPreviousChart = () => {
      const { activeChartIndex } = this.state;
      return (activeChartIndex > 0);
    };

    _canSelectNextChart = () => {
      const { charts, activeChartIndex } = this.state;
      const chartsAndGroups = this.getCurrentConfigBasedOnUrl(charts);
      const chartsLength = chartsAndGroups.charts.length;
      return (activeChartIndex < chartsLength - 1);
    };

    render() {
      const { title, charts, activeChartIndex, activeGroupIndex, type, loading, jobNumber, selectedCompany, interval, year, customInterval, hasToStartQuery } = this.state;
      const config = this.getCurrentConfigBasedOnUrl(charts);
      const activeCharts = (config && config.charts) ? config.charts : [];
      const foundChart = activeCharts.find((chart, i) => i === activeChartIndex);
      const selectableCharts = !!activeGroupIndex && !!charts.length ? charts[activeGroupIndex].charts : charts;

      return (
        <Container className='statistic-base-module-wrapper'>
          <Title title={title} />

          <ChartSelector
            charts={selectableCharts}
            activeChartIndex={activeChartIndex}
            onChartClick={this.onChartClick}
            onGroupClick={this.onGroupClick}
          />

          {
            foundChart &&
            <ChartWrapper
              chart={foundChart.chart}
              titleKey={foundChart.titleKey}
              filterType={foundChart.filterComponentName}
              type={type}
              selectedCompany={selectedCompany}
              year={year}
              intervalValue={interval}
              loading={loading}
              jobNumber={jobNumber}
              customInterval={customInterval}
              canNextClick={this._canSelectNextChart()}
              canPreviousClick={this._canSelectPreviousChart()}
              onNextClick={this.onNextChartClick}
              onPreviousClick={this.onPreviousChartClick}
              onJobNumberChange={this.onJobNumberChange}
              onCompanyChange={this.onCompanyChange}
              onCustomIntervalChange={this.onCustomIntervalChange}
              onIntervalChange={this.onIntervalChange}
              onYearChange={this.onYearChange}
              onStartButtonClick={() => this.setHasToStartQuery(true)}
            />
          }

          <ComposedComponent
            titleChange={this.onTitleChange}
            loadAllCharts={this.onLoadAllCharts}
            loadChart={this.onLoadChart}
            loadGroup={this.onLoadGroup}
            setTypeByCode={this.setTypeByCode}
            setLoading={this.setLoading}
            setHasToStartQuery={this.setHasToStartQuery}
            hasToStartQuery={hasToStartQuery}
            activeChartIndex={activeChartIndex}
            chartGroup={config ? config.groupId : null}
            loadedChartCount={charts.length}
            selectedCompany={selectedCompany}
            interval={interval}
            year={year}
            customInterval={customInterval}
            jobNumber={jobNumber}
            getChartComponent={this.getChartComponent}
          />
        </Container>
      );
    }
  }

  return BaseModule;
}
