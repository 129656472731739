export const TILES = [
  { titleKey: 'report', icon: 'fas fa-table', url: 'report', type: 'report' },
  { titleKey: 'casual', icon: 'fab fa-trello', url: 'casual', type: 'statistic' },
  { titleKey: 'projects', icon: 'fas fa-tasks', url: 'projects', type: 'statistic' },
  { titleKey: 'candidates', icon: 'fas fa-user-check', url: 'candidates', type: 'statistic', disabled: true },
  { titleKey: 'adverts', icon: 'fas fa-globe-europe', url: 'adverts', type: 'statistic', disabled: true },
  { titleKey: 'users', icon: 'fas fa-users', url: 'users', type: 'statistic', disabled: true },
  { titleKey: 'clients', icon: 'far fa-building', url: 'clients', type: 'statistic', disabled: true }
];

export const TILE_TYPES = {
  REPORT: 'report',
  STATISTIC: 'statistic'
};