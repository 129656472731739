import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoggedInWrapper from "../components/wrapper/LoggedInWrapper";

/**
 * Higher order component. Wraps every component which requires authentication.
 * It redirects to the /login page if the user is not authenticated. It gets the data from the 'auth' reducer.
 *
 * @param ComposedComponent
 */
export default function(ComposedComponent) {
  class Authenticate extends React.Component {
    componentDidMount() {
      if (!this.props.isAuthenticated) {
        this.props.history.push('/login');
      }
    }
    
    componentDidUpdate(nextProps) {
      if (!nextProps.isAuthenticated) {
        this.props.history.push('/login');
      }
    }
    
    render() {
      return (
        <ComposedComponent {...this.props} />
      );
    }
  }
  
  /**
   * This method is where the authentication can be turned on or off.
   * ----------------------------------------------------------------
   * With authentication:
   * ``
   * return {
   *   isAuthenticated: state.auth.isAuthenticated
   * };
   * ``
   *
   * Without authentication:
   * ``
   * return {
   *   isAuthenticated: true
   * };
   * ``
   *
   * @param state
   */
  function mapStateToProps(state) {
    return {
      isAuthenticated: state.auth.isAuthenticated
    };
  }
  
  Authenticate.contextTypes = {
    router: PropTypes.object
  };
  
  return connect(mapStateToProps)(LoggedInWrapper(Authenticate));
}
