import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react'

import BaseModule from "../BaseModule";
import translation from "../../../translations/translation";
import { setActiveChart } from "../../../../store/actions/statistics/commonActions";
import {
  initInboxCount,
  initProjectsCount,
  initProjectsByClass,
  initRecruitmentTypes,
  initPermanentEmployment,
  initCasualEmployment
} from "../../../../store/actions/statistics/projectActions";
import { ROUTES } from "../../../../config/routes";
import { ALL_PROJECTS_CHARTS } from "../../../../config/statistics/project";

class Projects extends Component {
  componentDidMount() {
    const { titleChange, loadAllCharts } = this.props;
    titleChange(translation.trans(ROUTES.PROJECTS.labelKey));
    loadAllCharts(Object.values(ALL_PROJECTS_CHARTS));
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { loadedChartCount, activeChart, hasToStartQuery, setHasToStartQuery } = this.props;
    if (activeChart && activeChart.id === ALL_PROJECTS_CHARTS.INBOX.id && hasToStartQuery) {
      await setHasToStartQuery(false);
    }
    if (!!loadedChartCount) {
      this.setChart();
    }
    if (activeChart && hasToStartQuery) {
      this.getChartData();
    }
  }

  setChart = () => {
    const { activeChartIndex, chartGroup } = this.props;
    this.props.setActiveChart(Object.values(ALL_PROJECTS_CHARTS), activeChartIndex, chartGroup);
  };

  getChartData = async () => {
    const { activeChart, getChartComponent, setHasToStartQuery, setLoading } = this.props;
    if (!!activeChart && !!activeChart.data && !!activeChart.data.length) {
      return;
    }
    if (!this.props[activeChart.getDataFunctionName]) {
      return;
    }

    const filterParamsForGetDataFn = this.getFilterParamsForGetDataFn();
    await setLoading(true);
    this.props[activeChart.getDataFunctionName](filterParamsForGetDataFn)
      .then(() => {
        activeChart.chart = getChartComponent(activeChart.type, activeChart.data, Object.values(activeChart.columns));
        this.props.loadChart(activeChart);
      })
      .catch(() => {
        activeChart.chart = getChartComponent(activeChart.type, null);
        this.props.loadChart(activeChart);
      })
      .finally(async () => {
        await setLoading(false);
        setHasToStartQuery(false);
      });
  };

  getFilterParamsForGetDataFn = () => {
    const { activeChart, jobNumber } = this.props;
    if (activeChart.id === ALL_PROJECTS_CHARTS.INBOX.id) {
      return { jobNumber };
    }

    return {};
  };

  render() {
    return (<Container />);
  }
}

const mapStateToProps = state => {
  return {
    activeChart: state.project.activeChart
  };
};

export default BaseModule(connect(mapStateToProps, {
  setActiveChart,
  initInboxCount,
  initProjectsCount,
  initProjectsByClass,
  initRecruitmentTypes,
  initPermanentEmployment,
  initCasualEmployment
})(Projects));