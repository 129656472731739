import React, { Component } from 'react';

import './BackButton.css';
import { ROUTES } from '../../../config/routes';

const DISABLED_ON = [ROUTES.DASHBOARD.url, ROUTES.PROFILE.url];

class BackButton extends Component {
  getRouteConfig = url => {
    return Object.values(ROUTES).find(route => route.url === url);
  };

  getParentRouteUrl = parent => {
    return ROUTES[parent.toUpperCase()].url;
  };

  onButtonClick = () => {
    const { match } = this.props;
    const routeConfig = this.getRouteConfig(match.url);
    if (!routeConfig.parent) {
      return;
    }

    const parentRouteUrl = this.getParentRouteUrl(routeConfig.parent);
    this.props.history.push(parentRouteUrl);
  };

  render() {
    const actualUrl = this.props.match.path;

    return !DISABLED_ON.includes(actualUrl) ? (
      <div className='back-button' onClick={this.onButtonClick}>
        <i className='fas fa-arrow-left' />
      </div>
    ) : null;
  }
}

export default BackButton;
