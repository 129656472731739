import translation from "../components/translations/translation";
import { CODE_OF_TYPES } from './settings';

export const REPORT_TYPES = [
  { value: 'hire', label: translation.trans('hire'), icon: 'fa fa-users', url: 'hire', code: CODE_OF_TYPES.KCS },
  { value: 'mediation', label: translation.trans('mediation'), icon: 'fas fa-sign-out-alt', url: 'mediation', code: CODE_OF_TYPES.KZV },
  { value: 'casual', label: translation.trans('casual'), icon: 'fab fa-trello', url: 'casual', code: CODE_OF_TYPES.ALK },
  { value: 'hr', label: translation.trans('hr'), icon: 'fas fa-phone-volume', url: 'hr', code: CODE_OF_TYPES.HR }
];

export const REPORT_CONDITIONS = {
  COMPANY: {
    label: translation.trans('company'),
    value: 'company',
    optionsName: 'companies',
    loadOptionsFnName: 'getCompaniesForSelectComponent'
  },
  OFFICE: {
    label: translation.trans('office'),
    value: 'office',
    optionsName: 'offices',
    loadOptionsFnName: 'getOfficesForSelectComponent'
  },
  CLIENT: {
    label: translation.trans('client'),
    value: 'client',
    optionsName: 'clients',
    loadOptionsFnName: 'getClientsForSelectComponent'
  },
  PROJECT: {
    label: translation.trans('project'),
    value: 'project',
    optionsName: 'projects',
    loadOptionsFnName: 'getProjectsForSelectComponent'
  },
  USER: {
    label: translation.trans('user'),
    value: 'user',
    optionsName: 'users',
    loadOptionsFnName: 'getUsersForSelectComponent'
  }
};

export const REPORT_STATUSES = {
  SUCCESS: {
    label: translation.trans('completed_file'),
    value: 'success'
  },
  PENDING: {
    label: translation.trans('pending_file'),
    value: 'pending'
  },
  FAILED: {
    label: translation.trans('failed_file'),
    value: 'failed'
  },
};

export const REPORT_LIST_SORT_OPTIONS = {
  DATE: {
    label: translation.trans('sort_by_date'),
    value: 'created_at'
  },
  NAME: {
    label: translation.trans('sort_by_name'),
    value: 'name'
  }
};

export const PROJECT_STATUS_LIST = {
  OPEN: { value: 'open', label: translation.trans('status_open'), defaultChecked: true },
  CLOSED: { value: 'closed', label: translation.trans('status_closed'), defaultChecked: true },
  DELETED: { value: 'deleted', label: translation.trans('status_deleted'), defaultChecked: false }
};