import { setAuthorizationToken } from "../config/axios/axiosInstance";

const storeTokens = (jwtToken, refreshToken) => {
  sessionStorage.setItem('jwtToken', jwtToken);
  sessionStorage.setItem('refreshToken', refreshToken);
  setAuthorizationToken(jwtToken);
};

const fetchJwtToken = () => {
  const jwtToken = sessionStorage.getItem('jwtToken');
  setAuthorizationToken(jwtToken);

  return jwtToken;
};

const fetchRefreshToken = () => {
  return sessionStorage.getItem('refreshToken');
};

const removeTokens = () => {
  sessionStorage.removeItem('jwtToken');
  sessionStorage.removeItem('refreshToken');
  setAuthorizationToken(false);
};

export { storeTokens, fetchJwtToken, fetchRefreshToken, removeTokens };