import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react'
import { connect } from "react-redux";

import './Header.css';
import CustomSelect from "../../../../form/select/CustomSelect";
import CustomSwitcher from "../../../../form/switcher/CustomSwitcher";
import translation from "../../../../translations/translation";
import { REPORT_CONDITIONS } from "../../../../../config/reports";

class Header extends Component {
  render() {
    const {
      isValidatingCurrentState, columns, reportTypeIsSelected, addedConditions,
      columnsSwitcherValue, conditionsSwitcherValue, savedConditionsSwitcherValue,
      onColumnsSwitcherChange,  onSavedConditionListSwitcherChange, onConditionsSwitcherChange,
      onAddConditionsChange
    } = this.props;
    const availableConditions = addedConditions && addedConditions.length ?
      Object.values(REPORT_CONDITIONS).filter(condition => !addedConditions.includes(condition.value))
      : Object.values(REPORT_CONDITIONS);
    const columnsIsLoaded = !!columns && !!columns.length;

    return (
      <Grid stackable verticalAlign='middle' className='condition-builder-header'>
        <Grid.Row>
          <Grid.Column width={16} className='switcher-container'>
            <CustomSwitcher
              text={translation.trans('conditions')}
              disabled={false}
              checked={conditionsSwitcherValue}
              onChange={onConditionsSwitcherChange}
            />
            <CustomSwitcher
              text={translation.trans('columns')}
              disabled={!columnsIsLoaded}
              checked={columnsSwitcherValue && columnsIsLoaded}
              onChange={onColumnsSwitcherChange}
            />
            <CustomSwitcher
              text={translation.trans('saved_conditions')}
              disabled={false}
              checked={savedConditionsSwitcherValue}
              onChange={onSavedConditionListSwitcherChange}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={2} only='computer tablet'>{translation.trans('add_conditions')}</Grid.Column>
          <Grid.Column width={2} only='mobile' textAlign='center'>{translation.trans('add_conditions')}</Grid.Column>
          <Grid.Column width={5}>
            <CustomSelect
              placeholder={translation.trans('conditions')}
              options={availableConditions}
              isDisabled={!availableConditions.length || !reportTypeIsSelected || isValidatingCurrentState}
              isLoading={isValidatingCurrentState}
              onChange={onAddConditionsChange}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };
}

function mapStateToProps(state) {
  return {
    columns: state.report.columns
  };
}

export default connect(mapStateToProps)(Header);