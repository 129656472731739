const removeTokens = () => {
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('refreshToken');
};

const setLocaleToStorage = locale => {
  localStorage.setItem('locale', locale);
};

const fetchLocaleFromStorage = () => {
  return localStorage.getItem('locale');
};

export { removeTokens, setLocaleToStorage, fetchLocaleFromStorage };