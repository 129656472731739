import React, { Component } from 'react';
import { Modal, List, Button, Divider } from 'semantic-ui-react'

import '../Base.css';
import './UserListToShare.css';
import translation from "../../translations/translation";
import CustomCheckboxWithLabel from '../../form/switcher/CustomCheckboxWithLabel';

const initialState = {
  selectedUsers: [],
  isPublic: false,
  isSaveButtonDisabled: true
};

class UserListToShare extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedUsers, isPublic, hasToReset } = this.props;
    if (prevProps.selectedUsers !== selectedUsers || prevProps.isPublic !== isPublic) {
      this.setState({ isPublic, selectedUsers: selectedUsers ? selectedUsers : [] });
    }

    if (prevProps.hasToReset !== hasToReset && hasToReset) {
      this._resetState();
    }
  }

  getUserList = () => {
    const { userList } = this.props;
    const { selectedUsers } = this.state;
    if (!userList || !userList.length) {
      return null;
    }

    return userList.map((user, index) => (
      <List.Item key={index}>
        <CustomCheckboxWithLabel
          label={user.full_name || user.username}
          withBorder={true}
          checked={selectedUsers ? !!selectedUsers.find(selected => selected.id === user.id) : false}
          onChange={(e, data) => this.onUserCheckboxClick(e, data, user.id)}
        />
      </List.Item>
    ));
  };

  onUserCheckboxClick = (e, { checked }, userId) => {
    const { userList } = this.props;
    const { selectedUsers } = this.state;
    if (!checked) {
      const newSelectedUsers = selectedUsers.filter(user => user.id !== userId);
      this.setState({ selectedUsers: newSelectedUsers, isSaveButtonDisabled: false });
      return;
    }
    const newUser = userList.find(user => user.id === userId);
    if (newUser) {
      selectedUsers.push(newUser);
      this.setState({ selectedUsers, isSaveButtonDisabled: false });
    }
  };

  onPublicCheckboxClick = (e, { checked }) => {
    this.setState({ isPublic: checked, isSaveButtonDisabled: false });
  };

  onSaveClick = () => {
    const { selectedUsers, isPublic } = this.state;
    const { queryId, onSave } = this.props;
    onSave(queryId, selectedUsers, isPublic);
  };

  onCloseClick = () => {
    const { onClose } = this.props;
    onClose();
  };

  _resetState = () => {
    this.setState(initialState);
  };

  render() {
    const { isOpen } = this.props;
    const { isPublic, isSaveButtonDisabled } = this.state;
    const userList = this.getUserList();

    return (
      <Modal open={isOpen} size='tiny' closeIcon dimmer='blurring' className='user-list-to-share'
             onClose={this.onCloseClick}>
        <Modal.Header>{translation.trans('sharing')}</Modal.Header>
        <Modal.Content scrolling>
          <CustomCheckboxWithLabel
            withBorder={true}
            checked={isPublic}
            label={translation.trans('public')}
            onChange={(e, data) => this.onPublicCheckboxClick(e, data)}
          />

          <Divider />

          <List>
            {userList}
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onSaveClick} disabled={isSaveButtonDisabled}>{translation.trans('done')}</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default UserListToShare;
