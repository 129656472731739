import React, { Component } from 'react';
import { Checkbox } from "semantic-ui-react";

import './CustomCheckbox.css';
import './CustomCheckbox';

class CustomCheckboxWithLabel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { checked } = this.props;
    if (prevState.checked !== checked) {
      this.setState({ checked });
    }
  }

  onCheckboxChange = (e, { checked }) => {
    const { onChange } = this.props;
    this.setState({ checked }, () => onChange(e, { checked }));
  };

  render() {
    const { label, id = null, withBorder = false, disabled = false } = this.props;
    const { checked } = this.state;

    return (
      <div className='custom-checkbox-with-label'>
        <Checkbox
          className={withBorder ? 'with-border' : null}
          checked={checked}
          disabled={disabled}
          id={id}
          onChange={this.onCheckboxChange}
        />
        <span>{label}</span>
      </div>
    );
  }
}

export default CustomCheckboxWithLabel;