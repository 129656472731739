import React from 'react';
import { Chart } from 'react-google-charts';

export const pieChart = ({ data, isDonut = false }) => {
  return (
    <React.Fragment>
      <Chart
        width={'100%'}
        height={'400px'}
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          pieHole: isDonut ? 0.4 : 0,
          animation: {
            startup: true,
            easing: 'linear',
            duration: 250,
          }
        }}
      />
    </React.Fragment>
  );
};