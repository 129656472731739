import React from 'react';
import { Container } from 'semantic-ui-react'

import './ChartCard.css';
import translation from "../../../translations/translation";

const chartCard = ({ titleKey, isActive, url, onClick }) => {
  const dynamicClass = isActive ? 'active' : '';

  return (
    <Container className={['chart-card', dynamicClass].join(' ')} onClick={() => onClick(url)}>
      {translation.trans(titleKey)}
    </Container>
  );
};

export default chartCard;
