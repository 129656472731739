import jwtDecode from 'jwt-decode';

import { axiosInstance } from "../../config/axios/axiosInstance";
import { SET_CURRENT_USER } from './types';
import { storeTokens, removeTokens, fetchRefreshToken } from '../../utils/sessionStorage';
import translation from "../../components/translations/translation";
import { addJwtRefreshResponseHandler, removeJwtRefreshResponseHandler } from "probond-axios-jwt-refresh/src";

/**
 * Helper function for the 'login' function in this file.
 *
 * @param user
 */

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user
  };
}

/**
 * This method is responsible for removing the JWT TOKEN from the local storage. Because there will be no token in the
 * local storage, the user will be redirected to the login page.
 */

export function logout() {
  return dispatch => {
    removeTokens();
    dispatch(setCurrentUser({}));
    removeJwtRefreshResponseHandler(axiosInstance);
  }
}

/**
 * This methods gets invoked in the LoginPage component. Sends the entered form data to the given url. On success,
 * it will set the returned JWT TOKEN into the local storage for further use and sets the returned user into the current
 * state.
 *
 * @param data
 */

export function login(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const { username, password } = data;
      let bodyData = {
        username,
        password
      };

      return axiosInstance.post('/ext/v2/login_check', bodyData)
        .then(res => {
          const statusCode = res.status;
          if (statusCode !== 200) {
            return Promise.reject(res);
          }

          const { token, refresh_token } = res.data;
          storeTokens(token, refresh_token);
          dispatch(setCurrentUser(jwtDecode(token)));
          registerRefreshJwtFunction();
          resolve();
        })
        .catch(err => {
          console.log('login failed!', err);
          reject(err);
        });
    });
  }
}

// the request has to be done immediately, otherwise the token refresh package will put it
// in a pending state
export const refreshJwtToken = (axios, refreshToken) => {
  return axios.post('/ext/v2/refresh', { refresh_token: refreshToken })
    .then(async res => {
      const { status, data } = res;
      if (status !== 200) {
        alert(translation.trans('you_need_relogin'));
        logout();
        return Promise.reject();
      }

      const { token, refresh_token } = data;
      storeTokens(token, refresh_token);
      setCurrentUser(jwtDecode(token));

      return token;
    })
    ;
};

export const isJwtExpired = axiosError => {
  const { response } = axiosError;
  if (!response) {
    return false;
  }
  
  const { data } = response;
  if (!data) {
    return false;
  }
  
  const { error } = data;
  if (!error) {
    return false;
  }
  
  const { code, expired } = error;
  if (!code || !expired) {
    return false;
  }
  
  return (code === 401 && expired === true);
};

export const registerRefreshJwtFunction = () => {
  const refreshToken = fetchRefreshToken();
  if (!refreshToken) {
    return;
  }
  
  addJwtRefreshResponseHandler(axiosInstance, isJwtExpired, refreshToken, refreshJwtToken);
};
