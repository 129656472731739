export const getSelectTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#2185d0',
    primary: '#5c7893',
    neutral10: '#2185d0',
    neutral80: '#ffffff'
  },
});

export const getSelectStyle = () => ({
  option: (provided, state) => {
    return {
      ...provided,
      borderBottom: '1px solid #e5e5e5',
      padding: 10,
      color: state.isSelected || state.isFocused ? '#ffffff' : '#314456',
      textAlign: 'left',
      transitionDuration: state.isSelected || state.isFocused ? '0.2s' : '0.3s'
    };
  },
  dropdownIndicator: provided => {
    return {
      ...provided,
      color: '#314456',
      transitionDuration: '0.2s',
      ':hover': {
        color: '#2185d0',
        transitionDuration: '0.2s'
      }
    };
  },
  control: (provided, state) => {
    return {
      ...provided,
      border: 'none',
      height: 38,
      transitionDuration: state.isSelected || state.isFocused ? '0.2s' : '0.5s'
    };
  },
  noOptionsMessage: provided => {
    return {
      ...provided,
      color: '#314456'
    };
  },
  input: provided => {
    return {
      ...provided,
      color: '#314456',
      top: '50%',
      height: 38
    };
  },
  singleValue: provided => {
    return {
      ...provided,
      color: '#314456'
    };
  },
  multiValue: provided => {
    return {
      ...provided,
      marginTop: 5
    };
  },
  multiValueRemove: provided => {
    return {
      ...provided,
      color: '#ffffff'
    };
  },
  valueContainer: provided => {
    return {
      ...provided,
      height: 38,
      position: 'initial',
    };
  }
});