import { AVAILABLE_LANGUAGES, PREDEFINED_INTERVALS } from "../config/settings";
import { START_YEAR_IN_DATABASE } from "../config/settings";
import translation from "../components/translations/translation";

const filterByLabel = (array, value) => {
  const result = array.filter(item => item.search.toLowerCase().indexOf(value.toLowerCase()) >= 0);
  return !!result.length ? result : [];
};

const addRequiredSelectParams = (options, valueKey, labelKey) =>
  options.map(option => ({
      ...option,
      value: option[valueKey],
      label: option[labelKey]
    })
  );

const filterIdsFromObjectsOfArray = array => array ? array.map(item => item.id || item) : null;

// const sortObjectsOfArrayByValue = (array, valueKey, isDesc = false) =>
//   array.sort((firstItem, secondItem) => {
//     if (firstItem[valueKey] < secondItem[valueKey]) return isDesc ? 1 : -1;
//     if (firstItem[valueKey] > secondItem[valueKey]) return isDesc ? -1 : 1;
//     return 0;
//   });

const validateInterval = (interval, customInterval) => {
  if (!interval) {
    return false;
  }
  if (interval !== PREDEFINED_INTERVALS.CUSTOM) {
    return true;
  }
  return !!(customInterval && customInterval.from && customInterval.to);
};

const getYearOptions = () => {
  const thisYear = new Date().getFullYear();
  let yearOptions = [];
  for (let i = thisYear; i >= START_YEAR_IN_DATABASE; i--) {
    yearOptions.push({
      label: i.toString(),
      value: i.toString()
    });
  }
  return yearOptions;
};

const getLanguageConfigFromLocale = locale => {
  const activeLanguage = AVAILABLE_LANGUAGES[locale.toUpperCase()];
  if (activeLanguage) {
    return activeLanguage;
  }

  
  return AVAILABLE_LANGUAGES[translation.getDefaultLocale().toUpperCase()];
};

const formatDateToYearMonthDay = date => {
  const year = date.getFullYear();
  const monthWithoutFormat = date.getMonth() + 1;
  const month = (monthWithoutFormat < 10) ? `0${monthWithoutFormat}` : monthWithoutFormat;
  const dayWithoutFormat = date.getDate();
  const day = (dayWithoutFormat < 10) ? `0${dayWithoutFormat}` : dayWithoutFormat;
  
  return `${year}-${month}-${day}`;
};

export {
  filterByLabel,
  addRequiredSelectParams,
  filterIdsFromObjectsOfArray,
  // sortObjectsOfArrayByValue,
  validateInterval,
  getYearOptions,
  getLanguageConfigFromLocale,
  formatDateToYearMonthDay
};