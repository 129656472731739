import React from 'react';
import { Chart } from 'react-google-charts';

export const lineChart = (props) => {
  const { data } = props;

  return (
    <React.Fragment>
      <Chart
        width={'100%'}
        height={'400px'}
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          animation: {
            startup: true,
            easing: 'linear',
            duration: 250,
          }
        }}
      />
    </React.Fragment>
  );
};