import {
  STATISTICS_CASUAL_UPDATE_EMPLOYMENT_REGISTRATION_COUNT
} from '../../actions/types';
import { axiosInstance } from "../../../config/axios/axiosInstance";
import { formatDateToYearMonthDay, validateInterval } from "../../../utils/common";

const initEmploymentRegistrationCount = (company, year, customInterval, interval, type) => dispatch => new Promise((resolve, reject) => {
  if (!validateInterval(interval, customInterval) || !company || !type) {
    console.error('missing or incorrect parameter to load employment registration count');
    reject();
    return;
  }
  const bodyData = {
    type,
    companyId: company.id,
    date: {
      year: year ? year.value : null,
      selected: interval.value,
      start: customInterval ? formatDateToYearMonthDay(customInterval.from) : null,
      end: customInterval ? formatDateToYearMonthDay(customInterval.to) : null
    }
  };

  return axiosInstance.post('/ext/v2/report/casual/assignment/count', bodyData)
    .then(response => {
      const { data, status } = response;
      if (!data || !data.data || !data.data[0].assignmentCount || status !== 200) {
        reject();
      }
      dispatch({
        type: STATISTICS_CASUAL_UPDATE_EMPLOYMENT_REGISTRATION_COUNT,
        data: data.data[0].assignmentCount
      });
      resolve();
    })
    .catch(error => {
      console.log('load employment registration count is failed', error);
      reject();
    });
});

export { initEmploymentRegistrationCount };