import React from 'react';

import './Base.css';
import './ReportTile.css';
import translation from '../../translations/translation';

const reportTile = ({ titleKey, icon, url, onClick }) => {
  return (
    <div className='category-box category-box-hover-animation report' onClick={() => onClick(url)}>
      <div><i className={icon} /></div>
      <div className='title'>{translation.trans(titleKey)}</div>
    </div>
  );
};

export default reportTile;
