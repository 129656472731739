import { axiosInstance } from './axiosInstance';

export const setAxiosInterceptors = () => {
  axiosInstance.interceptors.response.use(null, function (error) {
    const statusCode = error.response.status;
    if (statusCode === 403) {
      window.location = '403';
    }

    return Promise.reject(error);
  });
};
