import {
  STATISTICS_SET_ACTIVE_CHART,
  STATISTICS_CANDIDATES_UPDATE_CANDIDATES_COUNT,
  STATISTICS_CANDIDATES_UPDATE_REGISTERED_CANDIDATES_COUNT,
  STATISTICS_CANDIDATES_UPDATE_DEMOGRAPHY_STATISTICS
} from '../../actions/types';

const initialState = {
  activeChart: null,
  activeGroup: null
};

const setChartData = (state, data) => {
  const activeChart = state.activeChart;
  activeChart.data = data;

  return { ...state, activeChart };
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case STATISTICS_SET_ACTIVE_CHART:
      return { ...state, activeChart: action.data };

    case STATISTICS_CANDIDATES_UPDATE_CANDIDATES_COUNT:
    case STATISTICS_CANDIDATES_UPDATE_REGISTERED_CANDIDATES_COUNT:
      return setChartData(state, action.data);

    case STATISTICS_CANDIDATES_UPDATE_DEMOGRAPHY_STATISTICS:
      return { ...state, activeGroup: action.data };

    default:
      return state;
  }
};
