import React, { Component } from 'react';
import Select from 'react-select'

import { getSelectTheme, getSelectStyle } from './config';
import translation from "../../translations/translation";

export default class ReduxCompatibleSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  };

  onInputChange = value => {
    const { reportType, loadOptions } = this.props;
    this.setState({ isLoading: true }, () => {
      loadOptions(value, reportType)
        .finally(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  render() {
    const { options, placeholder, isMulti = false, onChange, isValidatingCurrentState } = this.props;
    const { isLoading } = this.state;
    const shouldDoLoading = isLoading || isValidatingCurrentState;

    return (
      <Select
        theme={getSelectTheme}
        styles={getSelectStyle()}
        options={options}
        value={null}
        placeholder={placeholder}
        isMulti={isMulti}
        blurInputOnSelect={true}
        captureMenuScroll={true}
        noOptionsMessage={({ inputValue }) => translation.trans(inputValue ? 'no_option' : 'start_writing')}
        loadingMessage={() => translation.trans('loading')}
        onChange={onChange}
        onInputChange={(e) => this.onInputChange(e)}
        isLoading={shouldDoLoading}
        isDisabled={shouldDoLoading}
      />
    );
  };
}
