import React from 'react';
import { Chart } from "react-google-charts";

export const barChart = ({ data, isStacked = false, isLabeling = false }) => {
  const labelingOptions = isLabeling ? {
    bar: { groupWidth: '95%' },
    legend: { position: 'none' }
  } : null;

  return (
    <React.Fragment>
      <Chart
        width={'100%'}
        height={'300px'}
        chartType="BarChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
          ...labelingOptions,
          chartArea: { width: '50%' },
          isStacked: isStacked,
          animation: {
            startup: true,
            easing: 'linear',
            duration: 250,
          }
        }}
      />
    </React.Fragment>
  );
};