import { combineReducers } from 'redux';
import auth from './auth';
import report from './report';
import profile from './profile';
import common from './statistics/common';
import candidate from './statistics/candidate';
import project from './statistics/project';
import advert from './statistics/advert';
import user from './statistics/user';
import client from './statistics/client';
import casual from './statistics/casual';

export default combineReducers({
  auth,
  candidate,
  report,
  profile,
  common,
  project,
  advert,
  user,
  client,
  casual
});
