import React from 'react';
import { Table } from 'semantic-ui-react'

import './TableChart.css';

export const tableChart = ({ columns, data }) => {
  const header = columns && columns.map((column, index) =>
    <Table.HeaderCell key={index}>{column.label}</Table.HeaderCell>);
  const row = columns && data && columns.map((column, index) => {
    const cellValue = data[column.value];
    return (<Table.Cell key={index}>{cellValue}</Table.Cell>);
  });

  return (
    <Table unstackable textAlign='center' verticalAlign='middle' className='table-chart'>
      <Table.Header>
        <Table.Row>
          {header}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        <Table.Row>
          {row}
        </Table.Row>
      </Table.Body>
    </Table>
  );
};