import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react'

import translation from "../../../../translations/translation";
import Interval from "../../../../form/interval/Interval";
import { PREDEFINED_INTERVALS } from '../../../../../config/settings';
import CustomSelect from "../../../../form/select/CustomSelect";
import CustomDateInterval from "../../../../form/datePicker/CustomDateInterval";
import CustomCheckbox from "../../../../form/switcher/CustomCheckbox";

class ReportInterval extends Component {
  render() {
    const {
      year, yearOptions, intervalValue, yearSelectorIsDisabled, startDate, endDate,
      onYearChange, onIntervalChange, onIntervalStartDateChange, onIntervalEndDateChange
    } = this.props;
    const intervalSelector = <CustomSelect
      value={intervalValue}
      options={Object.values(PREDEFINED_INTERVALS)}
      placeholder={translation.trans('interval')}
      onChange={onIntervalChange}
    />;
    const yearSelector = <CustomSelect
      value={year}
      options={yearOptions}
      placeholder={translation.trans('year')}
      isDisabled={yearSelectorIsDisabled}
      onChange={onYearChange}
    />;

    return (
      <React.Fragment>
        <Grid.Row only='computer tablet'>
          <Grid.Column width={1}><CustomCheckbox checked={true} /></Grid.Column>
          <Grid.Column width={2}>{translation.trans('interval')}</Grid.Column>

          <Grid.Column width={7}>
            {intervalSelector}
          </Grid.Column>

          <Grid.Column width={4}>
            {yearSelector}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row only='mobile'>
          <Grid.Column width={2}><CustomCheckbox checked={true} /></Grid.Column>
          <Grid.Column width={13}>{translation.trans('interval')}</Grid.Column>
        </Grid.Row>

        <Grid.Row only='mobile'>
          <Grid.Column stretched>
            {intervalSelector}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row only='mobile'>
          <Grid.Column stretched>
            {yearSelector}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={1} />
          <Grid.Column width={2} textAlign='right'>{PREDEFINED_INTERVALS.CUSTOM.label}</Grid.Column>
          <Grid.Column width={11}>
            <CustomDateInterval
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={onIntervalStartDateChange}
              onEndDateChange={onIntervalEndDateChange}
            />
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  };
}

export default Interval(ReportInterval);
