import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react'

import './ProjectStatusSelector.css';
import translation from "../../../../translations/translation";
import { PROJECT_STATUS_LIST } from '../../../../../config/reports';
import CustomCheckbox from "../../../../form/switcher/CustomCheckbox";
import CustomCheckboxWithLabel from "../../../../form/switcher/CustomCheckboxWithLabel";

class ProjectStatusSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectors: []
    }
  };

  componentDidMount() {
    const selectors = Object.values(PROJECT_STATUS_LIST).map(status => {
      status.checked = status.defaultChecked;
      return status;
    });
    this.sendSelectedStatuses(selectors);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { projectStatuses } = this.props;
    if (prevProps.projectStatuses !== projectStatuses) {
      const selectors = Object.values(PROJECT_STATUS_LIST).map(status => {
        status.checked = !!projectStatuses.length && projectStatuses.includes(status.value);
        return status;
      });
      this.setState({ selectors });
    }
  }

  sendSelectedStatuses = selectors => {
    const { onChange } = this.props;
    const checkedSelectors = selectors.filter(status => status.checked);
    onChange(checkedSelectors.map(status => status.value));
  };

  getCheckBox = (config, index) => {
    return (
      <CustomCheckboxWithLabel
        label={config.label}
        onChange={(e, x) => this.onCheckboxChange(e, x, index)}
        checked={config.checked} />
    );
  };

  getCheckboxSelectors = () => {
    const { selectors } = this.state;
    return selectors.map((status, index) => (
      <Grid.Column width={2} key={index}>{this.getCheckBox(status, index)}</Grid.Column>
    ));
  };

  getCheckBoxSelectorsMobile = () => {
    const { selectors } = this.state;
    return selectors.map((status, index) => (
      <React.Fragment key={index}>{this.getCheckBox(status, index)}</React.Fragment>
    ));
  };

  onCheckboxChange = (e, { checked }, index) => {
    const { selectors } = this.state;
    selectors[index].checked = checked;
    this.sendSelectedStatuses(selectors);
  };

  render() {
    const selectors = this.getCheckboxSelectors();
    const selectorsMobile = this.getCheckBoxSelectorsMobile();

    return (
      <React.Fragment>
        <Grid.Row only='computer tablet'>
          <Grid.Column width={1}><CustomCheckbox checked={true} /></Grid.Column>
          <Grid.Column width={2}>{translation.trans('project_status')}</Grid.Column>
          <Grid.Column width={11} className='information'>*{translation.trans('project_status_info')}</Grid.Column>
        </Grid.Row>

        <Grid.Row only='computer tablet'>
          <Grid.Column width={4} />
          {selectors}
        </Grid.Row>

        <Grid.Row only='mobile'>
          <Grid.Column width={1}><CustomCheckbox checked={true} /></Grid.Column>
          <Grid.Column width={13}>*{translation.trans('project_status')}</Grid.Column>
        </Grid.Row>

        <Grid.Row only='mobile'>
          <Grid.Column stretched className='information'>{translation.trans('project_status_info')}</Grid.Column>
        </Grid.Row>

        <Grid.Row only='mobile'>
          <Grid.Column width={1} />
          <Grid.Column>
            {selectorsMobile}
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  };
}

export default ProjectStatusSelector;