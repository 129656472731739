import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'semantic-ui-react'

import BaseModule from '../BaseModule';
import translation from '../../../translations/translation';
import { ALL_CASUAL_CHARTS } from '../../../../config/statistics/casual';
import { ROUTES } from "../../../../config/routes";
import { CODE_OF_TYPES } from "../../../../config/settings";
import { setActiveChart } from "../../../../store/actions/statistics/commonActions";
import { initEmploymentRegistrationCount } from "../../../../store/actions/statistics/casualActions";

class Casual extends Component {
  codeOfType = CODE_OF_TYPES.ALK;

  componentDidMount() {
    const { titleChange, loadAllCharts, setTypeByCode } = this.props;
    titleChange(translation.trans(ROUTES.CASUAL.labelKey));
    setTypeByCode(this.codeOfType);
    loadAllCharts(ALL_CASUAL_CHARTS);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { loadedChartCount, activeChart, selectedCompany, interval, year, customInterval } = this.props;
    if (!!loadedChartCount) {
      this.setChart();
    }
    if (prevProps.selectedCompany !== selectedCompany || prevProps.interval !== interval || prevProps.year !== year || prevProps.customInterval !== customInterval || activeChart) {
      this.getChartData();
    }
  }

  setChart = () => {
    const { activeChartIndex, chartGroup } = this.props;
    this.props.setActiveChart(ALL_CASUAL_CHARTS, activeChartIndex, chartGroup);
  };

  getChartData = async () => {
    const { activeChart, getChartComponent, selectedCompany, interval, year, customInterval, setLoading } = this.props;
    if (!this.props[activeChart.getDataFunctionName]) {
      return;
    }

    await setLoading(true);
    this.props[activeChart.getDataFunctionName](selectedCompany, year, customInterval, interval, this.codeOfType)
      .then(() => {
        activeChart.chart = getChartComponent(activeChart.type, activeChart.data);
        this.props.loadChart(activeChart);
      })
      .catch(() => {
        activeChart.chart = getChartComponent(activeChart.type, null);
        this.props.loadChart(activeChart);
      })
      .finally(() => setLoading(false));
  };

  render() {
    return (<Container />);
  }
}

const mapStateToProps = state => {
  return {
    activeChart: state.casual.activeChart
  };
};

export default BaseModule(connect(mapStateToProps, {
  setActiveChart,
  initEmploymentRegistrationCount
})(Casual));
