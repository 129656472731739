import React from 'react';

import './Base.css';
import './StatisticTile.css';
import translation from '../../translations/translation';

const statisticTile = ({ titleKey, icon, disabled, url, onClick }) => {
  const dynamicClasses = disabled ? 'disabled' : 'category-box-hover-animation';

  return (
    <div className={['category-box statistic', dynamicClasses].join(' ')}
         onClick={!disabled ? () => onClick(url) : null}>
      <div className='icon'><i className={icon} /></div>
      <div>{translation.trans(titleKey)}</div>
    </div>
  );
};

export default statisticTile;
