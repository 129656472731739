import { axiosInstance } from '../../config/axios/axiosInstance';
import translation from "../../components/translations/translation";
import {
  filterByLabel,
  addRequiredSelectParams,
  filterIdsFromObjectsOfArray,
  validateInterval
} from '../../utils/common';
import { hasAnyDataInTheArraysOfObject } from '../../utils/report';
import {
  REPORT_UPDATE_REPORT_TYPES, REPORT_UPDATE_COMPANIES_SELECT_COMPONENT,
  REPORT_UPDATE_OFFICES_SELECT_COMPONENT, REPORT_UPDATE_CLIENTS_SELECT_COMPONENT,
  REPORT_UPDATE_PROJECTS_SELECT_COMPONENT, REPORT_UPDATE_USERS_SELECT_COMPONENT,
  REPORT_START_QUERY, REPORT_GET_AVAILABLE_COLUMNS, REPORT_RESET_SELECT_COMPONENT_OPTIONS,
  REPORT_RESET_AVAILABLE_COLUMNS, REPORT_VALIDATE_CURRENT_REPORT_STATE,
  REPORT_GET_SAVED_REPORTS_LIST, REPORT_UPDATE_USER_LIST,
  REPORT_LOAD_REPORT
} from './types';
import { DEFAULT_QUERY_LIMIT } from "../../config/settings";
import { getReportTypeConfigByCode } from "../../utils/report";

const initialOfflineData = {
  companies: [],
  offices: [],
  clients: [],
  projects: [],
  users: []
};
let offlineData = { ...initialOfflineData };

const getReportTypes = () => dispatch => new Promise((resolve, reject) => {
  return axiosInstance.get('/ext/v2/report/types')
    .then(response => {
      const { data, status } = response;
      if (!data.data || status !== 200) {
        reject();
      }

      const result = addRequiredSelectParams(Object.values(data.data), 'code', 'name');
      dispatch({
        type: REPORT_UPDATE_REPORT_TYPES,
        data: result
      });
      resolve();
    })
    .catch(error => {
      console.log('load report types is failed', error);
      reject();
    });
});

const _loadCompanies = (typedValue, reportType) => new Promise((resolve => {
  if (!typedValue || typedValue.length < 3 || !reportType || !reportType.code) {
    return resolve([]);
  }

  return axiosInstance.get(`/ext/v2/report/autocomplete/company?q=${typedValue}&type=${reportType.code}`)
    .then(response => {
      const { data, status } = response;
      if (!data || status !== 200) {
        resolve([]);
      }

      const result = addRequiredSelectParams(Object.values(data.data), 'id', 'name');
      resolve(result);
    })
    .catch(error => {
      console.log('load companies for select component is failed', error);
      resolve([]);
    });
}));

const getCompaniesForSelectComponent = (typedValue, reportType) => dispatch => new Promise(async resolve => {
  let data = [];
  const offlineCompanies = offlineData.companies;
  if (offlineCompanies.length || hasAnyDataInTheArraysOfObject(offlineData)) {
    data = reportType ? filterByLabel([...offlineCompanies], typedValue) : [...offlineCompanies];
  } else {
    data = await _loadCompanies(typedValue, reportType);
  }

  dispatch({
    type: REPORT_UPDATE_COMPANIES_SELECT_COMPONENT,
    data
  });
  resolve();
});

const _loadOffices = (typedValue, reportType) => new Promise((resolve => {
  if (!typedValue || typedValue.length < 3 || !reportType || !reportType.code) {
    return resolve([]);
  }

  return axiosInstance.get(`/ext/v2/report/autocomplete/office?q=${typedValue}&type=${reportType.code}`)
    .then(response => {
      const { data, status } = response;
      if (!data || status !== 200) {
        resolve([]);
      }

      const result = addRequiredSelectParams(Object.values(data.data), 'id', 'name');
      resolve(result);
    })
    .catch(error => {
      console.log('load offices for select component is failed', error);
      resolve([]);
    });
}));

const getOfficesForSelectComponent = (typedValue, reportType) => dispatch => new Promise(async resolve => {
  let data = [];
  const offlineOffices = offlineData.offices;

  if (offlineOffices.length || hasAnyDataInTheArraysOfObject(offlineData)) {
    data = reportType ? filterByLabel([...offlineOffices], typedValue) : [...offlineOffices];
  } else {
    data = await _loadOffices(typedValue, reportType);
  }

  dispatch({
    type: REPORT_UPDATE_OFFICES_SELECT_COMPONENT,
    data
  });
  resolve();
});

const _loadClients = (typedValue, reportType) => new Promise((resolve => {
  if (!typedValue || typedValue.length < 3 || !reportType || !reportType.code) {
    return resolve([]);
  }

  return axiosInstance.get(`/ext/v2/report/autocomplete/client?q=${typedValue}&type=${reportType.code}`)
    .then(response => {
      const { data, status } = response;
      if (!data || status !== 200) {
        resolve([]);
      }

      const result = addRequiredSelectParams(Object.values(data.data), 'id', 'name');
      resolve(result);
    })
    .catch(error => {
      console.log('load clients for select component is failed', error);
      resolve([]);
    });
}));

const getClientsForSelectComponent = (typedValue, reportType) => dispatch => new Promise(async resolve => {
  let data = [];
  const offlineClients = offlineData.clients;

  if (offlineClients.length || hasAnyDataInTheArraysOfObject(offlineData)) {
    data = reportType ? filterByLabel([...offlineClients], typedValue) : [...offlineClients];
  } else {
    data = await _loadClients(typedValue, reportType);
  }

  dispatch({
    type: REPORT_UPDATE_CLIENTS_SELECT_COMPONENT,
    data
  });
  resolve();
});

const _loadProjects = (typedValue, reportType) => new Promise((resolve => {
  if (!typedValue || typedValue.length < 3 || !reportType || !reportType.code) {
    return resolve([]);
  }

  return axiosInstance.post(`/ext/v2/report/autocomplete/project?q=${typedValue}&type=${reportType.code}`)
    .then(response => {
      const { data, status } = response;
      if (!data || status !== 200) {
        resolve([]);
      }

      const result = addRequiredSelectParams(Object.values(data.data), 'id', 'name');
      resolve(result);
    })
    .catch(error => {
      console.log('load projects for select component is failed', error);
      resolve([]);
    });
}));

const getProjectsForSelectComponent = (typedValue, reportType) => dispatch => new Promise(async resolve => {
  let data = [];
  const offlineProjects = offlineData.projects;

  if (offlineProjects.length || hasAnyDataInTheArraysOfObject(offlineData)) {
    data = reportType ? filterByLabel([...offlineProjects], typedValue) : [...offlineProjects];
  } else {
    data = await _loadProjects(typedValue, reportType);
  }

  dispatch({
    type: REPORT_UPDATE_PROJECTS_SELECT_COMPONENT,
    data
  });
  resolve();
});

const _loadUsers = (typedValue, reportType) => new Promise((resolve => {
  if (!typedValue || typedValue.length < 3 || !reportType || !reportType.code) {
    return resolve([]);
  }

  return axiosInstance.post(`/ext/v2/report/autocomplete/user?q=${typedValue}&type=${reportType.code}`)
    .then(response => {
      const { data, status } = response;
      if (!data || status !== 200) {
        resolve([]);
      }

      const result = addRequiredSelectParams(Object.values(data.data), 'id', 'name');
      resolve(result);
    })
    .catch(error => {
      console.log('load users for select component is failed', error);
      resolve([]);
    });
}));

const getUsersForSelectComponent = (typedValue, reportType) => dispatch => new Promise(async resolve => {
  let data = [];
  const offlineUsers = offlineData.users;

  if (offlineUsers.length || hasAnyDataInTheArraysOfObject(offlineData)) {
    data = reportType ? filterByLabel([...offlineUsers], typedValue) : [...offlineUsers];
  } else {
    data = await _loadUsers(typedValue, reportType);
  }

  dispatch({
    type: REPORT_UPDATE_USERS_SELECT_COMPONENT,
    data: data
  });
  resolve();
});

const validateCurrentReportState = (state, reportType) => dispatch => new Promise((resolve, reject) => {
  // the response will contain the offline data for the selects
  // we have to save that to the 'offlineData' variable
  // might have to check the currently selected elements and compare them to the response
  if (!reportType || !reportType.code) {
    reject();
  }
  const companies = state.company ? filterIdsFromObjectsOfArray(state.company.selectedValues) : null;
  const offices = state.office ? filterIdsFromObjectsOfArray(state.office.selectedValues) : null;
  const clients = state.client ? filterIdsFromObjectsOfArray(state.client.selectedValues) : null;
  const projects = state.project ? filterIdsFromObjectsOfArray(state.project.selectedValues) : null;
  const users = state.user ? filterIdsFromObjectsOfArray(state.user.selectedValues) : null;

  return axiosInstance.get(`/ext/v2/report/grid?type=${reportType.code}`,
    { params: { companies, offices, clients, projects, users } })
    .then(response => {
      const { data, status } = response;
      if (!data || !data.data || status !== 200) {
        reject();
      }

      offlineData = {
        companies: addRequiredSelectParams(data.data.companies, 'id', 'name'),
        offices: addRequiredSelectParams(data.data.offices, 'id', 'name'),
        clients: addRequiredSelectParams(data.data.clients, 'id', 'name'),
        projects: addRequiredSelectParams(data.data.projects, 'id', 'name'),
        users: addRequiredSelectParams(data.data.users, 'id', 'name')
      };
      dispatch({
        type: REPORT_VALIDATE_CURRENT_REPORT_STATE,
        data: offlineData
      });
      resolve();
    })
    .catch(error => {
      console.log('load select components with correct values is failed', error);
      reject();
    });
});

const removeOfflineSelectData = () => dispatch => new Promise(resolve => {
  offlineData = { ...initialOfflineData };
  dispatch({
    type: REPORT_RESET_SELECT_COMPONENT_OPTIONS,
    data: offlineData
  });
  resolve();
});

const getAvailableColumns = reportType => dispatch => new Promise((resolve, reject) => {
  return axiosInstance.get(`/ext/v2/report/columns?type=${reportType.code}`)
    .then(response => {
      const { data, status } = response;
      if (!data.data || status !== 200) {
        reject();
      }
      const result = data.data;
      result.map(category => category.options = category.childrens);

      dispatch({
        type: REPORT_GET_AVAILABLE_COLUMNS,
        data: result
      });
      resolve();
    })
    .catch(error => {
      console.log('load available columns is failed', error);
      reject();
    });
});

const resetAvailableColumns = () => dispatch =>
  dispatch({
    type: REPORT_RESET_AVAILABLE_COLUMNS
  });

const startQuery = (interval, customInterval, year, reportType, query, columns, projectStatuses) => dispatch => new Promise((resolve, reject) => {
  if (!validateInterval(interval, customInterval) || !reportType || !reportType.code || !query || !columns.length || !projectStatuses.length) {
    reject();
  }
  const bodyData = {
    query,
    columns,
    type: reportType.code,
    date: {
      year: year ? year.value : null,
      selected: interval.value,
      start: customInterval ? customInterval.from : null,
      end: customInterval ? customInterval.to : null
    },
    project_filter: projectStatuses.join('|')
  };

  return axiosInstance.post('/ext/v2/user/report/start', bodyData)
    .then(response => {
      const { data, status } = response;
      if (!data || status !== 200) {
        reject();
      }

      dispatch({
        type: REPORT_START_QUERY,
        data
      });
      alert(translation.trans('query_successful_ran'));
      resolve();
    })
    .catch(error => {
      alert(translation.trans('query_run_failed'));
      console.log('start query is failed', error);
      reject();
    });
});

const addQuery = (reportType, query, title, columns, projectStatuses, usersSharedWith = [], sharable = false) => new Promise((resolve, reject) => {
  if (!reportType || !reportType.code || !query || !projectStatuses.length) {
    reject();
  }
  const bodyData = {
    query,
    title,
    usersSharedWith,
    sharable,
    columns,
    type: reportType.code,
    project_filter: projectStatuses.join('|')
  };

  return axiosInstance.post('/ext/v2/report/queries/add', bodyData)
    .then(response => {
      const { status } = response;
      if (status !== 201) {
        reject();
      }

      alert(translation.trans('query_is_saved'));
      resolve();
    })
    .catch(error => {
      alert(translation.trans('query_saving_failed'));
      console.log('start query is failed', error);
      reject();
    });
});

const loadSelectedConditionsById = conditions => {
  const newConditions = {};
  Object.keys(conditions).forEach(key => {
    const options = offlineData[conditions[key].optionsName];
    const selectedValues = options.filter(item => conditions[key].selectedValues.includes(item.id));
    newConditions[key] = { ...conditions[key], selectedValues };
  });

  return newConditions;
};


const getProfileSavedReportsList = (page, limit = DEFAULT_QUERY_LIMIT) => dispatch => new Promise((resolve, reject) => {
  return axiosInstance.get(`/ext/v2/report/queries?page=${page}&limit=${limit}`)
    .then(response => {
      const { data, status } = response;
      if (!data || status !== 200) {
        reject();
      }

      const result = {
        items: data.data.records,
        totalItems: data.data.totalItems
      };
      result.items = result.items.map(record => {
        if (!!record.type) {
          record.code = record.type;
          const reportType = getReportTypeConfigByCode(record.type);
          record.type = !!reportType ? reportType.label : null;
        }

        return record;
      });
      dispatch({
        type: REPORT_GET_SAVED_REPORTS_LIST,
        data: result
      });
      resolve();
    })
    .catch(error => {
      console.log('load pending report lists is failed', error);
      reject();
    });
});

const getUserList = () => dispatch => new Promise((resolve, reject) => {
  return axiosInstance.get(`ext/v2/user/report/available`)
    .then(response => {
      const { data, status } = response;
      if (!data || status !== 200) {
        reject();
      }

      dispatch({
        type: REPORT_UPDATE_USER_LIST,
        data: data.data
      });
      resolve();
    })
    .catch(error => {
      console.log('load user list is failed', error);
      reject();
    });
});

const loadReport = id => dispatch => new Promise((resolve, reject) => {
  return axiosInstance.get(`/ext/v2/report/queries/${id}`)
    .then(response => {
      const { data, status } = response;
      if (!data || status !== 200) {
        reject();
      }

      const result = data.data;
      result.type = getReportTypeConfigByCode(result.type);
      result.projectStatuses = result.project_filter ? result.project_filter.split('|') : [];
      result.columns = result.columns ? filterIdsFromObjectsOfArray(result.columns) : [];
      dispatch({
        type: REPORT_LOAD_REPORT,
        data: result
      });
      resolve();
    })
    .catch(error => {
      console.log('load report is failed', error);
      reject();
    });
});

const deleteReport = id => new Promise((resolve, reject) => {
  return axiosInstance.delete(`/ext/v2/report/queries/${id}/delete`)
    .then(response => {
      const { status } = response;
      status !== 204 ? reject() : resolve();
    })
    .catch(error => {
      console.log('delete report is failed', error);
      reject();
    });
});

const shareSavedConditions = (id, users, isSharable) => new Promise((resolve, reject) => {
  if (!id) {
    reject();
  }
  const bodyData = {
    users: users || !!users.length ? filterIdsFromObjectsOfArray(users) : null,
    query: id,
    public: isSharable
  };

  return axiosInstance.post(`/ext/v2/report/queries/${id}/multishare`, bodyData)
    .then(response => {
      const { status } = response;
      status !== 201 ? reject() : resolve();
    })
    .catch(error => {
      console.log('sharing report is failed', error);
      reject();
    });
});

export {
  getReportTypes,
  getCompaniesForSelectComponent,
  getOfficesForSelectComponent,
  getClientsForSelectComponent,
  getProjectsForSelectComponent,
  getUsersForSelectComponent,
  validateCurrentReportState,
  removeOfflineSelectData,
  getAvailableColumns,
  resetAvailableColumns,
  startQuery,
  addQuery,
  loadSelectedConditionsById,
  getProfileSavedReportsList,
  deleteReport,
  loadReport,
  getUserList,
  shareSavedConditions
};
