import React, { Component } from 'react';
import { Grid, Container } from 'semantic-ui-react';

import './ReportListFilter.css';
import CustomSelect from "../../form/select/CustomSelect";
import CustomButton from '../../form/button/CustomButton';

class ReportListFilter extends Component {
  render() {
    const {
      sortDirection, sortValue, sortOptions, sortIsDisabled,
      onSortDirectionChange, onSortValueChange
    } = this.props;
    const sortComponent = <>
      <Container className='select'>
        <CustomSelect options={sortOptions} value={sortValue} isDisabled={sortIsDisabled}
                      onChange={onSortValueChange} />
      </Container>
      <CustomButton onClick={() => onSortDirectionChange(sortDirection.value)} disabled={sortIsDisabled}>
        <i className={sortDirection.iconName} /></CustomButton>
    </>;

    return (
      <Grid stackable className='report-list-filter-container'>
        <Grid.Row only='computer'>
          <Grid.Column width={6} className='sort-container'>{sortComponent}</Grid.Column>
        </Grid.Row>

        <Grid.Row only='tablet'>
          <Grid.Column width={9} className='sort-container'>{sortComponent}</Grid.Column>
        </Grid.Row>

        <Grid.Row only='mobile'>
          <Grid.Column className='sort-container'>{sortComponent}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default ReportListFilter;