import { PROFILE_GET_REPORT_LIST } from '../actions/types';

const initialState = {
  reportList: {
    items: [],
    totalItems: 0
  }
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case PROFILE_GET_REPORT_LIST:
      return {
        ...state,
        reportList: action.data
      };
    default:
      return state;
  }
}
