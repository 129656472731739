import {
  STATISTICS_SET_ACTIVE_CHART,
  STATISTICS_ADVERTS_UPDATE_ADVERTS_COUNT,
  STATISTICS_ADVERTS_UPDATE_PLACE_OF_APPEARANCE,
  STATISTICS_ADVERTS_UPDATE_APPLICATIONS_COUNT,
  STATISTICS_ADVERTS_UPDATE_APPLICATIONS_BY_PORTAL
} from '../../actions/types';

const initialState = {
  activeChart: null
};

const setChartData = (state, data) => {
  const activeChart = state.activeChart;
  activeChart.data = data;

  return { ...state, activeChart };
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case STATISTICS_SET_ACTIVE_CHART:
      return { ...state, activeChart: action.data };

    case STATISTICS_ADVERTS_UPDATE_ADVERTS_COUNT:
    case STATISTICS_ADVERTS_UPDATE_PLACE_OF_APPEARANCE:
    case STATISTICS_ADVERTS_UPDATE_APPLICATIONS_COUNT:
    case STATISTICS_ADVERTS_UPDATE_APPLICATIONS_BY_PORTAL:
      return setChartData(state, action.data);

    default:
      return state;
  }
};
