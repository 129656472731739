import React, { Component } from 'react';
import { Container, Grid } from 'semantic-ui-react'

import './Dashboard.css';
import translation from '../translations/translation';
import { TILES, TILE_TYPES } from '../../config/dashboard';
import ReportTile from './tiles/ReportTile';
import StatisticTile from "./tiles/StatisticTile";
import Title from '../title/Title';

class Dashboard extends Component {
  getTiles = () => {
    return TILES.map((tile, i) => {
      if (!TILE_TYPES.hasOwnProperty(tile.type.toUpperCase())) {
        return null;
      }

      if (tile.type === TILE_TYPES.REPORT) {
        return <ReportTile
          key={i}
          titleKey={tile.titleKey}
          icon={tile.icon}
          url={tile.url}
          onClick={this.onTileClick}
        />;
      }

      return <StatisticTile
        key={i}
        titleKey={tile.titleKey}
        icon={tile.icon}
        url={tile.url}
        disabled={tile.disabled}
        onClick={this.onTileClick}
      />;
    });
  };

  onTileClick = (url) => {
    if (!url) {
      return;
    }

    this.props.history.push(url);
  };

  render() {
    const tileGrid = this.getTiles();

    return (
      <Container className='dashboard-container'>
        <Title title={translation.trans('dashboard')} />

        <Grid stackable centered>
          {tileGrid}
        </Grid>
      </Container>
    );
  }
}

export default Dashboard;
