import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import jwtDecode from 'jwt-decode';

import * as serviceWorker from './serviceWorker';
import reducers from './store/reducers';
import ScrollTo from './utils/ScrollTo';
import requireAuth from "./utils/requireAuth";
import { setCurrentUser } from './store/actions/authActions';
import { ROUTES } from './config/routes';
import { setAxiosInterceptors } from './config/axios/interceptor';
import { registerRefreshJwtFunction } from './store/actions/authActions';
import { fetchJwtToken } from './utils/sessionStorage';
import { removeTokens as removeTokensFromLocalStorage } from './utils/localStorage';
import translation from "./components/translations/translation";

import Dashboard from './components/dashboard/Dashboard';
import LoginPage from "./components/login/LoginPage";
import Profile from "./components/profile/Profile";
import ReportConditionBuilder from './components/reports/reportConditionBuilder/ReportConditionBuilder';
import Casual from "./components/statistics/modules/casual/Casual";
import Projects from "./components/statistics/modules/project/Projects";
import NotFoundPage from "./components/notFound/NotFoundPage";
import NotRolePage from "./components/noRole/NoRolePage";
// import Candidates from "./components/statistics/modules/candidate/Candidates";
// import Adverts from "./components/statistics/modules/advert/Adverts";
// import Users from "./components/statistics/modules/user/Users";
// import Clients from './components/statistics/modules/client/Clients';

import './index.css';
import "animate.css";
import 'semantic-ui-css/semantic.min.css';
import "skeleton-css/css/normalize.css";
import "skeleton-css/css/skeleton.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "react-datepicker/dist/react-datepicker.min.css";
import 'react-dual-listbox/lib/react-dual-listbox.css';

document.title = translation.trans('probond_statistics');

removeTokensFromLocalStorage();
const jwtToken = fetchJwtToken();
registerRefreshJwtFunction();
setAxiosInterceptors();

const store = createStore(
  reducers,
  compose(applyMiddleware(thunk))
);

if (jwtToken) {
  store.dispatch(setCurrentUser(jwtDecode(jwtToken)));
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollTo>
        <Switch>
          <Route exact path={ROUTES.DASHBOARD.url} component={requireAuth(Dashboard)} />
          <Route exact path={ROUTES.LOGIN.url} component={LoginPage} />
          <Route exact path={ROUTES.PROFILE.url} component={requireAuth(Profile)} />
          <Route exact path={ROUTES.REPORT.url} component={requireAuth(ReportConditionBuilder)} />
          <Route exact path={ROUTES.CASUAL.url} component={requireAuth(Casual)} />
          <Route exact path={ROUTES.PROJECTS.url} component={requireAuth(Projects)} />
          {/*<Route exact path={ROUTES.CANDIDATES.url} component={requireAuth(Candidates)} />*/}
          {/*<Route exact path={ROUTES.CANDIDATES_GROUP.url} component={requireAuth(Candidates)} />*/}
          {/*<Route exact path={ROUTES.ADVERTS.url} component={requireAuth(Adverts)} />*/}
          {/*<Route exact path={ROUTES.USERS.url} component={requireAuth(Users)} />*/}
          {/*<Route exact path={ROUTES.CLIENTS.url} component={requireAuth(Clients)} />*/}
          <Route exact path='/403' component={NotRolePage} />
          <Route exact component={NotFoundPage} />
        </Switch>
      </ScrollTo>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
