import axios from "axios";
import FileDownload from "js-file-download";

import { PROFILE_GET_REPORT_LIST } from './types';
import { getReportTypeConfigByCode, getReportTitle } from '../../utils/report';
import { DEFAULT_QUERY_LIMIT } from "../../config/settings";
import { axiosInstance } from "../../config/axios/axiosInstance";
import { fetchJwtToken } from "../../utils/sessionStorage";

const getReportList = (page, sort, dir, limit = DEFAULT_QUERY_LIMIT) => dispatch => new Promise((resolve, reject) => {
  return axiosInstance.get(`/ext/v2/user/report/jobs?page=${page}&limit=${limit}&sort=${sort}&dir=${dir}`)
    .then(response => {
      const { data, status } = response;
      if (!data || status !== 200) {
        reject();
      }

      dispatch({
        type: PROFILE_GET_REPORT_LIST,
        data: getReportListResult(data.data)
      });
      resolve();
    })
    .catch(error => {
      console.log('load report list lists is failed', error);
      reject();
    });
});

const getReportListResult = list => {
  const items = list ? list.records.map(record => {
    const reportType = getReportTypeConfigByCode(record.type);
    record.type = !!reportType ? reportType.label : null;
    if (!record.title) {
      record.title = record.file && record.file.name ? record.file.name : getReportTitle(record.status);
    }
    return record;
  }) : [];

  return {
    items,
    totalItems: !!list ? list.totalItems : 0
  };
};

const downloadFile = url => new Promise((resolve, reject) => {
  const jwtToken = fetchJwtToken();
  return axios.get(url, {
    responseType: 'blob',
    headers: {
      common: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`
      }
    }
  })
    .then(response => {
      const { data, status } = response;
      if (!data || status !== 200) {
        reject();
      }

      const helper = 'filename="';
      const contentDisposition = response.headers['content-disposition'];
      const filename = contentDisposition.substring(contentDisposition.lastIndexOf(helper) + helper.length, contentDisposition.length - 1);
      FileDownload(data, !!filename ? filename : `report_${new Date().valueOf()}.xlsx`);
      resolve();
    })
    .catch(error => {
      console.log('download file is failed', error);
      reject();
    });
});

export { getReportList, downloadFile };
