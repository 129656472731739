import React, { Component } from 'react';
import { Grid, Divider } from 'semantic-ui-react'
import { connect } from "react-redux";

import './ConditionSelectors.css';
import ConditionItem from "./conditionItem/ConditionItem";
import {
  getCompaniesForSelectComponent,
  getOfficesForSelectComponent,
  getClientsForSelectComponent,
  getProjectsForSelectComponent,
  getUsersForSelectComponent
} from "../../../../../store/actions/reportActions";

class ConditionSelectors extends Component {
  getConditionItems = () => {
    const {
      conditions, reportType, isValidatingCurrentState,
      onConditionCheckboxChange, onConditionSelectChange, onConditionTagDelete,
      onConditionTypeDelete
    } = this.props;

    return conditions ? Object.values(conditions).map((condition, index) => (
      <ConditionItem
        key={index}
        isValidatingCurrentState={isValidatingCurrentState}
        reportType={reportType}
        title={condition.label}
        conditionType={condition.value}
        options={this.props[condition.optionsName]}
        values={condition.selectedValues}
        loadOptions={this.props[condition.loadOptionsFnName]}
        checked={condition.checked}
        onSelectChange={onConditionSelectChange}
        onCheckboxChange={onConditionCheckboxChange}
        onConditionTagDelete={onConditionTagDelete}
        onConditionTypeDelete={onConditionTypeDelete}
      />
    )) : null;
  };

  render() {
    const conditionList = this.getConditionItems();

    return (
      <React.Fragment>
        {conditionList && conditionList.length && <Divider />}

        <Grid className='condition-selectors-container'>
          {conditionList}
        </Grid>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    companies: state.report.companies,
    offices: state.report.offices,
    clients: state.report.clients,
    projects: state.report.projects,
    users: state.report.users
  };
}

export default connect(mapStateToProps, {
  getCompaniesForSelectComponent,
  getOfficesForSelectComponent,
  getClientsForSelectComponent,
  getProjectsForSelectComponent,
  getUsersForSelectComponent,
})(ConditionSelectors);
