import {
  STATISTICS_SET_ACTIVE_CHART,
  STATISTICS_USERS_UPDATE_USER_GROUPS,
  STATISTICS_USERS_UPDATE_TOP_5_MOST_ACTIVE_USER,
  STATISTICS_USERS_UPDATE_TOP_RECRUITERS,
  STATISTICS_USERS_UPDATE_TOP_PROJECT_MANAGERS
} from '../../actions/types';

const initialState = {
  activeChart: null
};

const setChartData = (state, data) => {
  const activeChart = state.activeChart;
  activeChart.data = data;

  return { ...state, activeChart };
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case STATISTICS_SET_ACTIVE_CHART:
      return { ...state, activeChart: action.data };

    case STATISTICS_USERS_UPDATE_USER_GROUPS:
    case STATISTICS_USERS_UPDATE_TOP_5_MOST_ACTIVE_USER:
    case STATISTICS_USERS_UPDATE_TOP_RECRUITERS:
    case STATISTICS_USERS_UPDATE_TOP_PROJECT_MANAGERS:
      return setChartData(state, action.data);

    default:
      return state;
  }
};
