import React from 'react';
import Select from 'react-select'

import { getSelectTheme, getSelectStyle } from './config';
import translation from "../../translations/translation";

export default function CustomSelect({ options, placeholder = null, value = null, isMulti = false, isDisabled = false, isLoading = false, onChange }) {
  return (
    <Select
      theme={getSelectTheme}
      styles={getSelectStyle()}
      options={options}
      value={value}
      placeholder={placeholder ? `${placeholder}...` : null}
      isMulti={isMulti}
      isLoading={isLoading}
      isDisabled={isDisabled}
      blurInputOnSelect={true}
      captureMenuScroll={true}
      noOptionsMessage={({ inputValue }) => translation.trans(inputValue ? 'no_option' : 'start_writing')}
      loadingMessage={() => translation.trans('loading')}
      onChange={onChange}
    />
  );
};
