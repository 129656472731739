import React, { Component } from 'react';
import { connect } from "react-redux";
import { Prompt } from 'react-router-dom';
import { Confirm, Grid, Divider } from 'semantic-ui-react';

import './ReportConditionBuilder.css';
import { PREDEFINED_INTERVALS } from '../../../config/settings';
import translation from "../../translations/translation";
import Title from '../../title/Title';
import ConditionBuilder from "./conditionBuilder/ConditionBuilder";
import SavedConditionList from './savedConditionList/SavedConditionList';
import SaveReportModal from '../../modals/saveReport/SaveReport';
import {
  getCheckedConditions,
  getFormattedConditionsToSend,
  getConditionsWithConfig,
  getReportTypeConfigByCode, hasSelectedConditionTags
} from '../../../utils/report';
import {
  validateCurrentReportState,
  removeOfflineSelectData,
  getClientsForSelectComponent, getCompaniesForSelectComponent,
  getProjectsForSelectComponent, getUsersForSelectComponent,
  getOfficesForSelectComponent,
  startQuery,
  addQuery,
  loadSelectedConditionsById,
  resetAvailableColumns
} from "../../../store/actions/reportActions";

const initialState = {
  interval: null,
  conditions: null,
  reportName: null,
  year: null,
  customInterval: null,
  columns: [],
  newReportType: null,
  projectStatuses: [],
  saveReportModalIsOpen: false,
  isValidatingCurrentState: false,
  hasToRefreshSavedConditionList: false
};

class ReportConditionBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialState,
      reportType: null,
      reportTypeChangeConfirmModalIsOpen: false,
      isSavedConditionsListOpen: false
    };
  }

  async componentDidMount() {
    removeOfflineSelectData();
  }

  componentWillUnmount() {
    this._resetState();
  }

  loadSavedReport = (conditions, reportName, reportTypeCode, columns, projectStatuses) => {
    const reportType = getReportTypeConfigByCode(reportTypeCode);
    const conditionsWithConfig = getConditionsWithConfig(conditions);
    this.setState({
      reportName,
      reportType,
      columns,
      projectStatuses,
      conditions: conditionsWithConfig
    }, () => this._validateCurrentState());
  };

  setHasToRefreshSavedConditionList = value => {
    const { hasToRefreshSavedConditionList } = this.state;
    if (hasToRefreshSavedConditionList === value) {
      return;
    }
    this.setState({ hasToRefreshSavedConditionList: value });
  };

  onAddConditionsChange = selectedOption => {
    selectedOption.checked = true;
    selectedOption.selectedValues = [];
    this.setState(state => ({
      conditions: {
        ...state.conditions,
        [selectedOption.value]: selectedOption
      }
    }));
  };

  onIntervalChange = interval => {
    this.setState({ interval, customInterval: null });
  };

  onReportTypeChange = reportType => {
    const hasSelectedConditions = this._checkConditionsBeforeTypeChanging();
    if (hasSelectedConditions) {
      this.setState({ reportTypeChangeConfirmModalIsOpen: true, newReportType: reportType });
      return;
    }

    this.setState({ reportType });
  };

  onCustomIntervalChange = (from, to) => {
    this.setState({
      customInterval: { from, to },
      interval: PREDEFINED_INTERVALS.CUSTOM
    });
  };

  onYearChange = year => {
    this.setState({ year });
  };

  onConditionCheckboxChange = (e, { checked }, conditionType) => {
    this.setState(state => ({
      conditions: {
        ...state.conditions,
        [conditionType]: {
          ...state.conditions[conditionType],
          checked
        }
      }
    }));
  };

  onConditionSelectChange = (data, conditionType) => {
    const { selectedValues } = this.state.conditions[conditionType];
    const checkedData = Array.isArray(data) ? data : [];
    const newSelectedValues = [...selectedValues, ...checkedData];
    this.setState(state => ({
      conditions: {
        ...state.conditions,
        [conditionType]: {
          ...state.conditions[conditionType],
          selectedValues: newSelectedValues
        }
      }
    }), this._validateCurrentState);
  };

  onConditionTagDelete = (id, conditionType) => {
    const { selectedValues } = this.state.conditions[conditionType];
    const newSelectedValues = selectedValues.filter(condition => condition.id !== id);
    this.setState(state => ({
      conditions: {
        ...state.conditions,
        [conditionType]: {
          ...state.conditions[conditionType],
          selectedValues: newSelectedValues
        }
      }
    }), this._validateCurrentState);
  };

  onConditionTypeDelete = conditionType => {
    const conditions = { ...this.state.conditions };
    delete conditions[conditionType];
    this.setState({ conditions }, this._validateCurrentState);
  };

  onProjectStatusChange = projectStatuses => {
    this.setState({ projectStatuses });
  };

  onColumnsChange = columns => {
    this.setState({ columns });
  };

  onRunQueriesClick = () => {
    const { startQuery } = this.props;
    const { conditions, interval, year, reportType, customInterval, columns, projectStatuses } = this.state;
    if (!conditions || !columns.length) {
      return;
    }

    const checkedConditions = getCheckedConditions(conditions);
    const formattedConditions = getFormattedConditionsToSend(checkedConditions);
    startQuery(interval, customInterval, year, reportType, formattedConditions, columns, projectStatuses);
  };

  onSaveConditionsClick = () => {
    this.setState({ saveReportModalIsOpen: true });
  };

  onSaveReportClick = reportName => {
    this.setState({ saveReportModalIsOpen: false, reportName }, () => {
      const { conditions, reportType, columns, projectStatuses } = this.state;
      const checkedConditions = getCheckedConditions(conditions);
      const formattedConditions = getFormattedConditionsToSend(checkedConditions);
      addQuery(reportType, formattedConditions, reportName, columns, projectStatuses).then(() => {
        this.setHasToRefreshSavedConditionList(true);
      });
    });
  };

  onCancelSaveReportClick = () => {
    this.setState({ saveReportModalIsOpen: false });
  };

  onConfirmReportTypeChange = () => {
    const { newReportType } = this.state;
    this.setState({ reportTypeChangeConfirmModalIsOpen: false, reportType: newReportType },
      this._resetState);
  };

  onCancelReportTypeChange = () => {
    this.setState({ reportTypeChangeConfirmModalIsOpen: false, newReportType: null });
  };

  onSavedConditionListSwitcherChange = (e, { checked }) =>
    this.setState({ isSavedConditionsListOpen: checked });

  _checkUnsavedConditions = () => {
    const { interval, conditions, columns } = this.state;

    return hasSelectedConditionTags(conditions) || !!interval || !!columns.length;
  };

  _checkConditionsBeforeTypeChanging = () => {
    const { conditions, columns } = this.state;

    return hasSelectedConditionTags(conditions) || !!columns.length;
  };

  _validateCurrentState = (hasToLoadSelectedConditions = false) => {
    this.setState({ isValidatingCurrentState: true }, () => {
      const { reportType, conditions } = this.state;
      const {
        getCompaniesForSelectComponent,
        getOfficesForSelectComponent,
        getClientsForSelectComponent,
        getProjectsForSelectComponent,
        getUsersForSelectComponent,
        validateCurrentReportState
      } = this.props;

      validateCurrentReportState(conditions, reportType)
        .finally(() => {
          Promise.all([
            getCompaniesForSelectComponent,
            getOfficesForSelectComponent,
            getClientsForSelectComponent,
            getProjectsForSelectComponent,
            getUsersForSelectComponent
          ]).then(() => {
            if (hasToLoadSelectedConditions) {
              const newConditions = loadSelectedConditionsById(conditions);
              this.setState({ isValidatingCurrentState: false, conditions: newConditions });
              return;
            }

            this.setState({ isValidatingCurrentState: false });
          });
        });
    });
  };

  _resetState = () => {
    const { resetAvailableColumns, removeOfflineSelectData } = this.props;
    resetAvailableColumns();
    removeOfflineSelectData();
    this.setState({ ...initialState });
  };

  render() {
    const {
      reportName, reportType, conditions, columns, interval, customInterval, year,
      saveReportModalIsOpen, isValidatingCurrentState, reportTypeChangeConfirmModalIsOpen,
      isSavedConditionsListOpen, hasToRefreshSavedConditionList, projectStatuses
    } = this.state;
    const hasUnsavedConditions = this._checkUnsavedConditions();
    const builder = <ConditionBuilder
      intervalValue={interval}
      customInterval={customInterval}
      reportType={reportType}
      conditions={conditions}
      columns={columns}
      year={year}
      projectStatuses={projectStatuses}
      isValidatingCurrentState={isValidatingCurrentState}
      savedConditionsSwitcherValue={isSavedConditionsListOpen}
      onSavedConditionListSwitcherChange={this.onSavedConditionListSwitcherChange}
      onCustomIntervalChange={this.onCustomIntervalChange}
      onIntervalChange={this.onIntervalChange}
      onYearChange={this.onYearChange}
      onReportTypeChange={this.onReportTypeChange}
      onAddConditionsChange={this.onAddConditionsChange}
      onConditionCheckboxChange={this.onConditionCheckboxChange}
      onConditionSelectChange={this.onConditionSelectChange}
      onConditionTagDelete={this.onConditionTagDelete}
      onConditionTypeDelete={this.onConditionTypeDelete}
      onProjectStatusChange={this.onProjectStatusChange}
      onColumnsChange={this.onColumnsChange}
      onRunQueryClick={this.onRunQueriesClick}
      onSaveClick={this.onSaveConditionsClick}
    />;
    const savedConditionList = <SavedConditionList
      hasToRefresh={hasToRefreshSavedConditionList}
      loadSavedReport={this.loadSavedReport}
      setHasToRefreshSavedConditionList={this.setHasToRefreshSavedConditionList}
      isValidatingCurrentState={isValidatingCurrentState}
    />;

    return (
      <React.Fragment>
        <Prompt message={translation.trans('are_you_sure_unsaved_conditions')} when={hasUnsavedConditions} />
        <Confirm
          content={translation.trans('are_you_sure_you_want_change_report_type')}
          cancelButton={translation.trans('cancel')}
          size='tiny'
          open={reportTypeChangeConfirmModalIsOpen}
          onConfirm={this.onConfirmReportTypeChange}
          onCancel={this.onCancelReportTypeChange}
        />
        <SaveReportModal
          isOpen={saveReportModalIsOpen}
          reportName={reportName}
          onSave={this.onSaveReportClick}
          onCancel={this.onCancelSaveReportClick}
        />

        <Title title={translation.trans('report')} />
        <Grid className='report-condition-builder-container'>
          <Grid.Row only='computer tablet'>
            <Grid.Column width={isSavedConditionsListOpen ? 9 : 16}>{builder}</Grid.Column>
            {
              isSavedConditionsListOpen ?
                <Grid.Column width={6} className='saved-conditions-column-with-border'>
                  {savedConditionList}
                </Grid.Column>
                : null
            }
          </Grid.Row>

          <Grid.Row only='mobile'>
            <Grid.Column width={16}>{builder}</Grid.Column>
          </Grid.Row>

          {
            isSavedConditionsListOpen ?
              <Grid.Row only='mobile'>
                <Grid.Column width={16} className='saved-conditions-column' only='mobile'>
                  <Divider />
                  {savedConditionList}
                </Grid.Column>
              </Grid.Row>
              : null
          }
        </Grid>
      </React.Fragment>
    );
  }
}

export default connect(null, {
  getCompaniesForSelectComponent,
  getOfficesForSelectComponent,
  getClientsForSelectComponent,
  getProjectsForSelectComponent,
  getUsersForSelectComponent,
  startQuery,
  resetAvailableColumns,
  validateCurrentReportState,
  removeOfflineSelectData
})(ReportConditionBuilder);
