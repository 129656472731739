import {
  REPORT_UPDATE_REPORT_TYPES, REPORT_UPDATE_COMPANIES_SELECT_COMPONENT,
  REPORT_UPDATE_OFFICES_SELECT_COMPONENT, REPORT_UPDATE_CLIENTS_SELECT_COMPONENT,
  REPORT_UPDATE_PROJECTS_SELECT_COMPONENT, REPORT_UPDATE_USERS_SELECT_COMPONENT,
  REPORT_GET_AVAILABLE_COLUMNS, REPORT_RESET_SELECT_COMPONENT_OPTIONS,
  REPORT_RESET_AVAILABLE_COLUMNS, REPORT_VALIDATE_CURRENT_REPORT_STATE,
  REPORT_GET_SAVED_REPORTS_LIST, REPORT_UPDATE_USER_LIST,
  REPORT_LOAD_REPORT
} from "../actions/types";

const initialState = {
  companies: [],
  offices: [],
  clients: [],
  projects: [],
  users: [],
  columns: [],
  reportTypes: [],
  savedReports: {
    items: [],
    totalItems: 0
  },
  userListToShare: [],
  selectedReport: null
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case REPORT_UPDATE_COMPANIES_SELECT_COMPONENT:
      return {
        ...state,
        companies: action.data
      };
    case REPORT_UPDATE_OFFICES_SELECT_COMPONENT:
      return {
        ...state,
        offices: action.data
      };
    case REPORT_UPDATE_CLIENTS_SELECT_COMPONENT:
      return {
        ...state,
        clients: action.data
      };
    case REPORT_UPDATE_PROJECTS_SELECT_COMPONENT:
      return {
        ...state,
        projects: action.data
      };
    case REPORT_UPDATE_USERS_SELECT_COMPONENT:
      return {
        ...state,
        users: action.data
      };
    case REPORT_GET_AVAILABLE_COLUMNS:
      return {
        ...state,
        columns: action.data
      };
    case REPORT_RESET_AVAILABLE_COLUMNS:
      return {
        ...state,
        columns: initialState.columns
      };
    case REPORT_UPDATE_REPORT_TYPES:
      return {
        ...state,
        reportTypes: action.data
      };
    case REPORT_VALIDATE_CURRENT_REPORT_STATE:
    case REPORT_RESET_SELECT_COMPONENT_OPTIONS:
      return {
        ...state,
        ...action.data
      };
    case REPORT_GET_SAVED_REPORTS_LIST:
      return {
        ...state,
        savedReports: action.data
      };
    case REPORT_UPDATE_USER_LIST:
      return {
        ...state,
        userListToShare: action.data
      };
    case REPORT_LOAD_REPORT:
      return {
        ...state,
        selectedReport: action.data
      };
    default:
      return state;
  }
}
