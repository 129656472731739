import React from 'react';
import { Container } from 'semantic-ui-react'

import './OneInputFilter.css';
import translation from "../../../translations/translation";
import CustomInput from '../../../form/input/CustomInput';
import CustomButton from '../../../form/button/CustomButton';

const oneInputFilter = ({ value, placeholder, onValueChange, onStartButtonClick }) => {
  return (
    <Container className='one-input-filter-container'>
      <CustomInput placeholder={placeholder} value={value} onChange={onValueChange} />
      <CustomButton onClick={onStartButtonClick}>{translation.trans('start_query')}</CustomButton>
    </Container>
  );
};

export default oneInputFilter;