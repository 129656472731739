import React from 'react';
import { Container } from 'semantic-ui-react'

import './NoRolePage.css';
import translation from "../translations/translation";
import CustomButton from '../form/button/CustomButton';

const noRolePage = ({ history }) => {
  return (
    <Container className='no-role-page-container'>
      <Container className='content'>
        <div className='code'>403</div>
        <div>
          {translation.trans('role_not_found')}...
        </div>
        <div className='info-text'>
          {translation.trans('contact_with_support')}
        </div>
        <div className='go-to-dashboard'>
          <CustomButton size='big' onClick={() => {
            history.push('/')
          }}>{translation.trans('dashboard')}</CustomButton>
        </div>
      </Container>
    </Container>
  );
};

export default noRolePage;
