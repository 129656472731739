import React from 'react';

import './TagItem.css';

const tagItem = ({ label, isDisabled, onDeleteClick }) => {
  const dynamicClass = isDisabled ? 'disabled' : null;

  return (
    !!label ?
      <div className={['tag-container', dynamicClass].join(' ')}>
        {label}
        <span className='delete' onClick={onDeleteClick}><i className='fas fa-times' /></span>
      </div>
      : null
  );
};

export default tagItem;