import React, { Component } from 'react';
import { Accordion, Grid, Icon } from 'semantic-ui-react'

import './ChartSelector.css';
import ChartCard from './card/ChartCard';
import translation from "../../translations/translation";

class ChartSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  getChartList = () => {
    const { charts, activeChartIndex, onChartClick, onGroupClick } = this.props;
    if (!charts || !charts.length) {
      return;
    }

    return charts.map((chart, index) => {
      const clickEvent = !!chart.groupId ? onGroupClick : onChartClick;

      return (
        <Grid.Column key={index} width={3}>
          <ChartCard
            titleKey={chart.titleKey}
            isActive={index === activeChartIndex}
            url={chart.url}
            onClick={() => clickEvent(index)}
          />
        </Grid.Column>
      );
    });
  };

  toggleChartCards = () => {
    this.setState(state => ({
      isOpen: !state.isOpen
    }));
  };

  render() {
    const { isOpen } = this.state;
    const list = this.getChartList();

    return (
      <Accordion className='chart-selector-container'>
        {list && list.length > 1 && (
          <>
            <Accordion.Title active={isOpen} onClick={this.toggleChartCards}>
              <Icon name='dropdown' />
              {translation.trans('chart_selector')}
            </Accordion.Title>
            <Accordion.Content active={isOpen}>
              <Grid centered stackable>
                {list}
              </Grid>
            </Accordion.Content>
          </>
        )}
      </Accordion>
    );
  }
}

export default ChartSelector;
