import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react'

import translation from "../../../translations/translation";
import Interval from "../../../form/interval/Interval";
import { PREDEFINED_INTERVALS } from '../../../../config/settings';
import CustomSelect from "../../../form/select/CustomSelect";
import CustomDateInterval from "../../../form/datePicker/CustomDateInterval";

class StatisticsInterval extends Component {
  render() {
    const {
      year, yearOptions, intervalValue, yearSelectorIsDisabled, startDate, endDate,
      onYearChange, onIntervalChange, onIntervalStartDateChange, onIntervalEndDateChange
    } = this.props;
    const intervalSelector = <CustomSelect
      value={intervalValue}
      options={Object.values(PREDEFINED_INTERVALS)}
      placeholder={translation.trans('interval')}
      onChange={onIntervalChange}
    />;
    const yearSelector = <CustomSelect
      value={year}
      options={yearOptions}
      placeholder={translation.trans('year')}
      isDisabled={yearSelectorIsDisabled}
      onChange={onYearChange}
    />;
    const customDateIntervalSelector = <CustomDateInterval
      startDate={startDate}
      endDate={endDate}
      onStartDateChange={onIntervalStartDateChange}
      onEndDateChange={onIntervalEndDateChange}
    />;

    return (
      <React.Fragment>
        <Grid.Row only='computer'>
          <Grid.Column width={3}>
            {intervalSelector}
          </Grid.Column>

          <Grid.Column width={3}>
            {yearSelector}
          </Grid.Column>

          <Grid.Column width={8} textAlign='center'>
            {customDateIntervalSelector}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row only='tablet mobile'>
          <Grid.Column stretched>
            {intervalSelector}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row only='tablet mobile'>
          <Grid.Column stretched>
            {yearSelector}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row only='tablet mobile'>
          <Grid.Column stretched textAlign='center'>
            {customDateIntervalSelector}
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  };
}

export default Interval(StatisticsInterval);
