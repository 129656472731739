import React, { Component } from 'react';
import { Table, Container } from 'semantic-ui-react'
import { connect } from "react-redux";

import './Profile.css';
import Title from '../title/Title';
import translation from "../translations/translation";
import ReportListFilter from './reportListFilter/ReportListFilter';
import ReportListFileItem from './reportListFileItem/ReportListFileItem';
import CustomPagination from '../pagination/CustomPagination';
import Spinner from '../spinner/Spinner';
import { getReportList, downloadFile } from '../../store/actions/profileActions';
import { SORTING_DIRECTIONS } from "../../config/settings";
import { REPORT_LIST_SORT_OPTIONS } from '../../config/reports';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortValue: REPORT_LIST_SORT_OPTIONS.DATE,
      sortDirection: SORTING_DIRECTIONS.DESC,
      loading: false
    };
  }

  componentDidMount() {
    this.loadListItems();
  }

  setLoading = (loading) => this.setState({ loading });

  loadListItems = (page = 1) => {
    const { getReportList } = this.props;
    const { sortValue, sortDirection } = this.state;
    this.setState({ loading: true }, () => {
      getReportList(page, sortValue.value, sortDirection.value)
        .finally(() => this.setLoading(false));
    });
  };

  onSortValueChange = sortValue => {
    this.setState({ sortValue }, this.loadListItems);
  };

  onSortDirectionChange = value => {
    const sortDirection = Object.values(SORTING_DIRECTIONS).find(direction =>
      direction.value !== value);
    if (!sortDirection) {
      return;
    }

    this.setState({ sortDirection }, this.loadListItems);
  };

  onPaginationChange = activePage => {
    this.loadListItems(activePage);
  };

  _getReportListItems = items => items.map((item, i) => (
    <ReportListFileItem
      key={i}
      title={item.title}
      type={item.type}
      status={item.status ? translation.trans(item.status) : null}
      createDate={item.createdAt.date}
      onDownloadClick={item.file && item.file.url ? () => downloadFile(item.file.url) : null}
    />
  ));

  render() {
    const { reportList } = this.props;
    const { loading, sortDirection, sortValue } = this.state;
    const listItems = reportList ? this._getReportListItems(reportList.items) : [];
    const itemsTotalCount = reportList ? reportList.totalItems : 0;
    const paginationKey = null;
    const sortOptions = Object.values(REPORT_LIST_SORT_OPTIONS);

    return (
      <React.Fragment>
        <Title title={translation.trans('profile')} />

        <Container className='profile-report-list-wrapper'>
          <ReportListFilter
            sortDirection={sortDirection}
            sortValue={sortValue}
            sortOptions={sortOptions}
            sortIsDisabled={!listItems.length}
            onSortDirectionChange={this.onSortDirectionChange}
            onSortValueChange={this.onSortValueChange}
          />

          {
            loading ? <Spinner /> :
              <Table textAlign='center'>
                <Table.Body>
                  {(!listItems.length) ? (
                    <Table.Row><Table.Cell>{translation.trans('no_item_to_show')}</Table.Cell></Table.Row>) : listItems}
                </Table.Body>
              </Table>
          }
          {
            !!listItems.length &&
            <CustomPagination
              key={paginationKey}
              totalItems={itemsTotalCount}
              loading={loading}
              onPageChange={this.onPaginationChange}
            />
          }
        </Container>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    reportList: { ...state.profile.reportList }
  };
}

export default connect(mapStateToProps, { getReportList })(Profile);
