import React from 'react';
import { Container, Button, } from "semantic-ui-react";

import './SavedConditionItem.css';
import ButtonWithLoading from '../../../../form/button/ButtonWithLoading';
import CustomButton from '../../../../form/button/CustomButton';

const savedConditionItem = ({ title, isDisabled, onClick, onShareClick, onDeleteClick }) => {
  const dynamicClasses = isDisabled ? 'disabled' : null;

  return (
    <Container className={['saved-condition-item', dynamicClasses].join(' ')}>
      <div className='title' onClick={!isDisabled ? onClick : null}>{title}</div>
      <Button.Group>
        <CustomButton onClick={!isDisabled ? onShareClick : null} isInGroup={true} disabled={isDisabled || !onShareClick} popUpTextKey='share'><i
          className='fas fa-share-alt' /></CustomButton>
        <ButtonWithLoading onClickEvent={!isDisabled ? onDeleteClick : null} popUpTextKey='delete' isInGroup={true} disabled={isDisabled || !onDeleteClick}><i
          className='fas fa-times' /></ButtonWithLoading>
      </Button.Group>
    </Container>
  );
};

export default savedConditionItem;
