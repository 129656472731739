import { fetchLocaleFromStorage, setLocaleToStorage } from "../../utils/localStorage";

const DEFAULT_LOCALE = 'en';
const translations = {
  hu: {
    'hungarian': 'Magyar',
    'english': 'Angol',
    'romanian': 'Román',
    'dashboard': 'Áttekintés',
    'report': 'Riport',
    'report_type': 'Riport típus',
    'hire': 'Kölcsönzés',
    'mediation': 'Közvetítés',
    'candidates': 'Jelöltek',
    'project': 'Projekt',
    'projects': 'Projektek',
    'company': 'Cég',
    'office': 'Iroda',
    'client': 'Ügyfél',
    'clients': 'Ügyfelek',
    'adverts': 'Hirdetések',
    'user': 'Felhasználó',
    'users': 'Felhasználók',
    'job_number': 'Munkaszám',
    'chart_selector': 'Diagram választó',
    'casual': 'Alkalmi',
    'user_groups': 'Felhasználói csoportok',
    'top_5_most_active_user': 'Top 5 legaktívabb felhasználó',
    'top_recruiters': 'Top toborzók',
    'top_project_managers': 'Top projektvezetők',
    'coming_soon': 'Hamarosan',
    'name': 'Név',
    'number_of_adverts': 'Hirdetések száma',
    'appearance_places_and_number_of_applications': 'Megjelenés helye/jelentkezők száma',
    'number_of_applications': 'Jelentkezések száma',
    'applications_by_advertising_portal': 'Jelentkezések száma hirdetési portálonként',
    'number_of_clients': 'Ügyfelek száma',
    'client_requests': 'Ügyfél megrendelések',
    'number_of_projects': 'Projektek száma',
    'projects_by_class': 'Projektek osztályonként',
    'recruitment_types': 'Toborzási típusok',
    'permanent_employment': 'Állandó munkaviszony',
    'casual_employment': 'Alkalmi munkaviszony',
    'gender': 'Nem',
    'age': 'Életkor',
    'permanent_address': 'Lakhely',
    'educations': 'Tanulmányok',
    'demographic_data': 'Demográfiai adatok',
    'candidate_type': 'Jelölt típus',
    'basic_data': 'Alap adatok',
    'number_of_candidates': 'Jelöltek száma',
    'number_of_new_candidates': 'Új jelöltek száma',
    'number_of_candidates_by_sources': 'Jelöltek száma bekerülési források szerint',
    'number_of_employment_registrations': 'Bejelentések száma',
    'statistics': 'Statisztikák',
    'username': 'Felhasználónév',
    'password': 'Jelszó',
    'login': 'Bejelentkezés',
    'profile': 'Profil',
    'logout': 'Kijelentkezés',
    'save': 'Mentés',
    'interval': 'Intervallum',
    'start_query': 'Lekérdezés indítása',
    'success': 'Kész',
    'pending': 'Függő',
    'failed': 'Sikertelen',
    'saved_conditions': 'Mentett feltételek',
    'no_item_to_show': 'Nincs megjeleníthető elem',
    'no_data_to_show': 'Nincs megjeleníthető adat',
    'sharing': 'Megosztás',
    'done': 'Kész',
    'cancel': 'Mégse',
    'are_you_sure_unsaved_conditions': 'Biztosan elhagyod az oldalt? A nem mentett feltételek elvesznek.',
    'report_settings': 'Riport beállítások',
    'query_successful_ran': 'A lekérdezés bekerült a függőben lévő fájlok közé. Az állapotát nyomonkövetheted a Profilodon.',
    'query_run_failed': 'A lekérdezés futtatásakor hiba történt!',
    'view': 'Megtekintés',
    'share': 'Megosztás',
    'delete': 'Törlés',
    'download': 'Letöltés',
    'edit': 'Szerkesztés',
    'last_week': 'Előző hét',
    'last_two_week': 'Előző két hét',
    'last_month': 'Előző hónap',
    'last_half_year': 'Előző fél év',
    'last_year': 'Előző év',
    'actual_year': 'Aktuális év',
    'actual_month': 'Aktuális hónap',
    'year': 'Év',
    'custom': 'Egyéni',
    'you_need_relogin': 'Hiba történt. Jelentkezz be újra!',
    'pending_file': 'Függő fájl',
    'completed_file': 'Elkészült fájl',
    'failed_file': 'Sikertelen file',
    'query_is_saved': 'Lekérdezés mentve.',
    'query_saving_failed': 'Lekérdezés mentése sikertelen volt!',
    'add_conditions': 'Feltételek hozzáadása',
    'conditions': 'Feltételek',
    'columns': 'Oszlopok',
    'today': 'Ma',
    'from': '-tól',
    'to': '-ig',
    'selecting_columns': 'Oszlopok kiválasztása',
    'page_not_found': 'Az oldal nem található',
    'role_not_found': 'Az oldalhoz nincs jogosultságod',
    'contact_with_support': 'Vedd fel a kapcsolatot a Support-tal!',
    'are_you_sure_you_want_change_report_type': 'Biztosan megváltoztatod a riport típusát? A kiválasztott feltételek elvesznek.',
    'no_option': 'Nincs találat',
    'start_writing': 'Kezdj el gépelni...',
    'loading': 'Betöltés',
    'start_date_higher_than_end_date': 'A kezdődátum nagyobb, mint a végdátum! Válaszd ki újra a dátumokat!',
    'sort_by_date': 'Időpont alapján rendezés',
    'sort_by_name': 'Név alapján rendezés',
    'all': 'Mind',
    'missing_parameter_to_load_saved_condition': 'A mentett feltétel betöltéséhez hiányzik egy paraméter.',
    'inbox': 'Inbox',
    'viewed': 'Olvasott',
    'un_viewed': 'Olvasatlan',
    'project_status': 'Projekt státusz',
    'status_open': 'Nyitott',
    'status_closed': 'Zárt',
    'status_deleted': 'Törölt',
    'project_status_info': 'Legalább egy feltétel kiválasztása szükséges ahhoz, hogy a generált Riport excelbe eredményt lehessen megjeleníteni!',
    'public': 'Publikus',
    'sharing_success': 'A megosztás sikeres volt.',
    'sharing_failed': 'A megosztás sikertelen volt.',
    'probond_statistics': 'Probond Statisztikák',
    'hr': 'HR'
  },
  en: {
    'hungarian': 'Hungarian',
    'english': 'English',
    'romanian': 'Romanian',
    'dashboard': 'Dashboard',
    'report': 'Report',
    'report_type': 'Report type',
    'hire': 'Hire',
    'mediation': 'Mediation',
    'candidates': 'Candidates',
    'project': 'Project',
    'projects': 'Projects',
    'company': 'Company',
    'office': 'Office',
    'client': 'Client',
    'clients': 'Clients',
    'adverts': 'Adverts',
    'user': 'User',
    'users': 'Users',
    'job_number': 'Job number',
    'chart_selector': 'Chart selector',
    'casual': 'Casual',
    'user_groups': 'User groups',
    'top_5_most_active_user': 'Top 5 most active user',
    'top_recruiters': 'Top recruiters',
    'top_project_managers': 'Top project managers',
    'coming_soon': 'Coming soon',
    'name': 'Name',
    'number_of_adverts': 'Number of adverts',
    'appearance_places_and_number_of_applications': 'Appearance places and number of applications',
    'number_of_applications': 'Number of applications',
    'applications_by_advertising_portal': 'Applications by advertising portal',
    'number_of_clients': 'Number of clients',
    'client_requests': 'Client requests',
    'number_of_projects': 'Number of projects',
    'projects_by_class': 'Projects by class',
    'recruitment_types': 'Recruitment types',
    'permanent_employment': 'Permanent employment',
    'casual_employment': 'Casual employment',
    'gender': 'Gender',
    'age': 'Age',
    'permanent_address': 'Permanent address',
    'educations': 'Educations',
    'demographic_data': 'Demographic Data',
    'candidate_type': 'Candidate type',
    'basic_data': 'Basic data',
    'number_of_candidates': 'Number of candidates',
    'number_of_new_candidates': 'Number of new candidates',
    'number_of_candidates_by_sources': 'Number of candidates by sources',
    'number_of_employment_registrations': 'Number of employment registrations',
    'statistics': 'Statistics',
    'username': 'Username',
    'password': 'Password',
    'login': 'Login',
    'profile': 'Profile',
    'logout': 'Logout',
    'save': 'Save',
    'interval': 'Interval',
    'start_query': 'Start query',
    'success': 'Completed',
    'pending': 'Pending',
    'failed': 'Failed',
    'saved_conditions': 'Saved conditions',
    'no_item_to_show': 'No item to show',
    'no_data_to_show': 'No data to show',
    'sharing': 'Sharing',
    'done': 'Done',
    'cancel': 'Cancel',
    'are_you_sure_unsaved_conditions': 'Are you sure you leave this page? The unsaved conditions going to be lost.',
    'report_settings': 'Report settings',
    'query_successful_ran': 'The query has been added to the pending files. You can keep tracking its status on your Profile.',
    'query_run_failed': 'An error occurred while running the query.',
    'view': 'View',
    'share': 'Share',
    'delete': 'Delete',
    'download': 'Download',
    'edit': 'Edit',
    'last_week': 'Last week',
    'last_two_week': 'Last two week',
    'last_month': 'Last month',
    'last_half_year': 'Last half year',
    'last_year': 'Last year',
    'actual_year': 'Actual year',
    'actual_month': 'Actual month',
    'year': 'Year',
    'custom': 'Custom',
    'you_need_relogin': 'An error occurred. You need relogin!',
    'pending_file': 'Pending file',
    'completed_file': 'Completed file',
    'failed_file': 'Failed file',
    'query_is_saved': 'The query is saved.',
    'query_saving_failed': 'The query saving is failed!',
    'add_conditions': 'Add conditions',
    'conditions': 'Conditions',
    'columns': 'Columns',
    'today': 'Today',
    'from': 'From',
    'to': 'To',
    'selecting_columns': 'Selecting columns',
    'page_not_found': 'Page Not Found',
    'role_not_found': "You don't have the necessary role to access this page",
    'contact_with_support': 'Please contact the Support team to resolve this issue!',
    'are_you_sure_you_want_change_report_type': 'Are you sure you want to change the report type? The selected conditions going to be lost.',
    'no_option': 'No option',
    'start_writing': 'Start writing...',
    'loading': 'Loading',
    'start_date_higher_than_end_date': 'The start date is higher than end date! Select the dates again!',
    'sort_by_date': 'Sort by date',
    'sort_by_name': 'Sort by name',
    'all': 'All',
    'missing_parameter_to_load_saved_condition': 'Missing parameter to load saved condition.',
    'inbox': 'Inbox',
    'viewed': 'Viewed',
    'un_viewed': 'Unviewed',
    'project_status': 'Project status',
    'status_open': 'Open',
    'status_closed': 'Closed',
    'status_deleted': 'Deleted',
    'project_status_info': 'At least one selected condition is required to result can be displayed in the generated Report excel!',
    'public': 'Public',
    'sharing_success': 'The sharing was success.',
    'sharing_failed': 'The sharing is failed.',
    'probond_statistics': 'Probond Statistics',
    'hr': 'HR'
  }
};

class Translation {
  #activeLocale;

  constructor() {
    const locale = this.getPossibleLocale();
    this.setLocale(locale);
  }

  getPossibleLocale() {
    const locale = fetchLocaleFromStorage();
    if (locale) {
      return locale;
    }

    const browserLocale = navigator.language;
    const availableLocales = Object.keys(translations);
    const currentLocale = availableLocales.find(locale => browserLocale.includes(locale));
    return currentLocale ? currentLocale : this.getDefaultLocale();
  }

  setLocale(value) {
    this.#activeLocale = value;
    setLocaleToStorage(value);
  }
  
  getActiveLocale() {
    return this.#activeLocale;
  }
  
  getDefaultLocale() {
    return DEFAULT_LOCALE;
  }

  trans(key) {
    const translation = translations[this.#activeLocale][key];
    return (translation) ? translation : `__${key}__`;
  }
}

const translation = new Translation();
export default translation;
