import React, { Component } from 'react';
import { Container, Divider } from 'semantic-ui-react'

import './ConditionBuilder.css';
import Header from "./header/Header";
import Footer from "./footer/Footer";
import RequiredConditionsBox from "./requiredConditionsBox/RequiredConditionsBox";
import ConditionSelectors from "./conditionSelectors/ConditionSelectors";
import ColumnSelector from "./columnSelector/ColumnSelector";
import { hasSelectedConditionTags } from "../../../../utils/report";
import { validateInterval } from "../../../../utils/common";

class ConditionBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isColumnsSelectorOpen: true,
      isConditionSelectorsOpen: true
    };
  }

  setIsColumnsSelectorOpen = (e, { checked }) =>
    this.setState({ isColumnsSelectorOpen: checked });

  setIsConditionSelectorsOpen = (e, { checked }) =>
    this.setState({ isConditionSelectorsOpen: checked });

  render() {
    const {
      reportType, conditions, columns, intervalValue, isValidatingCurrentState, year,
      savedConditionsSwitcherValue, customInterval, projectStatuses,
      onAddConditionsChange, onCustomIntervalChange, onIntervalChange,
      onConditionCheckboxChange, onConditionSelectChange, onConditionTagDelete,
      onColumnsChange, onConditionTypeDelete, onRunQueryClick, onSaveClick,
      onReportTypeChange, onYearChange, onSavedConditionListSwitcherChange,
      onProjectStatusChange
    } = this.props;
    const { isColumnsSelectorOpen, isConditionSelectorsOpen } = this.state;
    const addedConditions = conditions ? Object.keys(conditions) : null;
    const isRunQueryButtonDisabled = !hasSelectedConditionTags(conditions) || !reportType || !validateInterval(intervalValue, customInterval) || !columns.length || isValidatingCurrentState || !projectStatuses.length;
    const isSaveButtonDisabled = !hasSelectedConditionTags(conditions) || !reportType || isValidatingCurrentState || !projectStatuses.length;

    return (
      <Container className='condition-builder-container'>
        <Header
          isValidatingCurrentState={isValidatingCurrentState}
          reportTypeIsSelected={!!reportType}
          addedConditions={addedConditions}
          columnsSwitcherValue={isColumnsSelectorOpen}
          conditionsSwitcherValue={isConditionSelectorsOpen}
          savedConditionsSwitcherValue={savedConditionsSwitcherValue}
          onColumnsSwitcherChange={this.setIsColumnsSelectorOpen}
          onConditionsSwitcherChange={this.setIsConditionSelectorsOpen}
          onSavedConditionListSwitcherChange={onSavedConditionListSwitcherChange}
          onAddConditionsChange={onAddConditionsChange}
        />

        {
          isConditionSelectorsOpen ?
            (<React.Fragment>
              <Divider />
              <RequiredConditionsBox
                year={year}
                reportTypeValue={reportType}
                intervalValue={intervalValue}
                projectStatuses={projectStatuses}
                onCustomIntervalChange={onCustomIntervalChange}
                onIntervalChange={onIntervalChange}
                onYearChange={onYearChange}
                onReportTypeChange={onReportTypeChange}
                onProjectStatusChange={onProjectStatusChange}
              />
              <ConditionSelectors
                conditions={conditions}
                reportType={reportType}
                onConditionCheckboxChange={onConditionCheckboxChange}
                onConditionSelectChange={onConditionSelectChange}
                onConditionTagDelete={onConditionTagDelete}
                onConditionTypeDelete={onConditionTypeDelete}
                isValidatingCurrentState={isValidatingCurrentState}
              />
            </React.Fragment>)
            : null
        }

        {
          isColumnsSelectorOpen ?
            <ColumnSelector
              reportType={reportType}
              selectedColumns={columns}
              onColumnsChange={onColumnsChange}
            />
            : null
        }

        <Footer
          isRunQueryButtonDisabled={isRunQueryButtonDisabled}
          isSaveButtonDisabled={isSaveButtonDisabled}
          onRunQueryClick={onRunQueryClick}
          onSaveClick={onSaveClick}
        />
      </Container>
    );
  }
}

export default ConditionBuilder;
