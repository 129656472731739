import React, { Component } from 'react';
import { Breadcrumb } from 'semantic-ui-react'

import './Breadcrumb.css';
import { ROUTES } from '../../../config/routes';
import translation from "../../translations/translation";

class BreadcrumbComponent extends Component {
  getBreadcrumbComponents = () => {
    const matchedUrl = this.props.match.path;
    const breadcrumbs = this.collectBreadcrumbs(matchedUrl);
    if (breadcrumbs.length === 1) {
      return null;
    }

    return breadcrumbs.map((breadcrumbItem, i) => {
        const isActive = breadcrumbs.length === i + 1;
        const separator = i > 0 && <Breadcrumb.Divider icon='right chevron' />;
        const labelKey = breadcrumbItem.labelKey ? breadcrumbItem.labelKey : this.getLabelKeyFromParamState();

        return (
          <React.Fragment key={i}>
            {separator}
            {
              isActive ?
                <Breadcrumb.Section active>{translation.trans(labelKey)}</Breadcrumb.Section> :
                <Breadcrumb.Section link onClick={() => this.onBreadcrumbClick(breadcrumbItem.url)}>
                  {translation.trans(labelKey)}
                </Breadcrumb.Section>
            }
          </React.Fragment>
        );
      }
    );
  };

  getLabelKeyFromParamState = () => {
    const { location } = this.props;
    return location.state ? location.state.labelKey : null;
  };

  collectBreadcrumbs = (url) => {
    let breadcrumbs = [];
    const route = this.getRouteByUrl(url);

    if (!route) {
      return breadcrumbs;
    }

    breadcrumbs.push(route);
    if (route.parent) {
      const parentUrl = ROUTES[route.parent.toUpperCase()].url;
      const parentRoute = this.getRouteByUrl(parentUrl);
      const parentBreadcrumb = this.collectBreadcrumbs(parentRoute.url);
      breadcrumbs = parentBreadcrumb.concat(breadcrumbs);
    }

    return breadcrumbs;
  };

  getRouteByUrl = (url) => {
    return Object.values(ROUTES).find(route => route.url === url);
  };

  onBreadcrumbClick = (url) => {
    this.props.history.push(url);
  };

  render() {
    const breadcrumbs = this.getBreadcrumbComponents();

    return (
      <Breadcrumb size='big'>
        {breadcrumbs ? breadcrumbs : null}
      </Breadcrumb>
    );
  }
}

export default BreadcrumbComponent;
