import {
  STATISTICS_SET_ACTIVE_CHART,
  STATISTICS_UPDATE_COMPANIES_SELECT_COMPONENT
} from '../types';
import { axiosInstance } from "../../../config/axios/axiosInstance";
import { addRequiredSelectParams } from "../../../utils/common";

const getCompaniesForSelectByType = type => dispatch => new Promise((resolve => {
  if (!type) {
    return resolve([]);
  }

  return axiosInstance.get(`/ext/v2/report/companies?type=${type}`)
    .then(response => {
      const { data, status } = response;
      if (!data || status !== 200) {
        resolve([]);
      }
      const result = addRequiredSelectParams(Object.values(data.data), 'id', 'name');
      dispatch({
        type: STATISTICS_UPDATE_COMPANIES_SELECT_COMPONENT,
        data: result
      });
      resolve();
    })
    .catch(error => {
      console.log('load companies for select component by type is failed', error);
      resolve([]);
    });
}));

/**
 * This method set the active chart.
 *
 * @param allCharts
 * @param activeChartIndex
 * @param chartGroup
 * @returns {Function}
 */
const setActiveChart = (allCharts, activeChartIndex, chartGroup) => dispatch => {
  let data = allCharts[activeChartIndex];

  if (chartGroup) {
    const group = allCharts.find(chart => {
      return chart.groupId === chartGroup
    });
    data = group.charts[activeChartIndex];
  }

  dispatch({
    type: STATISTICS_SET_ACTIVE_CHART,
    data
  });
};

export {
  setActiveChart,
  getCompaniesForSelectByType
};
